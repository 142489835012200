function initLocationMap() {

    function createTooltip(obj) {
        var string ='';
        obj.units.forEach(function (unit) {
            var marker_phone = '';
            var marker_fax = '';
            var marker_header = '<h5>' + unit.organization + '</h5>';
            var marker_street = obj["street"] + '<br/>';
            var marker_city = obj["post"] + ' ' + obj["city"] + '</br>';
            if (unit["phone"]) marker_phone = '<span class="phone-tab">Tel:</span>' + unit["phone"] + '</br>';
            if (unit["fax"]) marker_fax = '<span class="phone-tab">Fax:</span>' + unit["fax"] + '</br>';
            var marker_subhead = "";
            if (unit["name"]) {
                marker_subhead = '<h6>' + unit["name"] + '</h6>';
            }
            string += '<div>' + marker_header + marker_subhead + '<div class="tooltip-addr">' + marker_street + marker_city+ '</div>' + marker_phone + marker_fax + '</div>'
        });

        return '<div class="marker">' + string + '</div>';

    }

    //Get coordinates from marker and create marker
    function geocodeAddress(obj, markers_array, from_search) {
        var info = createTooltip(obj);
        map.setCenter(obj.coords);
        var mapImage = image;
        var type = obj.type;
        if (type == 'hq') { mapImage = image_hq};
        if (type == 'affiliate') { mapImage = image_affiliate};

        var marker = new google.maps.Marker({
            id: obj.id,
            map: map,
            icon: mapImage,
            position: obj.coords
        });

        if (markers_array) {
            markers_array[obj.id] = marker;
            if (from_search){
                infowindow.setContent(info);
                infowindow.open(map, marker);
            } else {
                init_bounds.extend(marker.getPosition());
                map.fitBounds(init_bounds);
            }
        }
        else {
            search_marker = marker;
        }

        marker.addListener('click', function () {
            infowindow.setContent(info);
            infowindow.open(map, marker);
        });
    }

    function filterMarkers(filter) {

        var bounds = new google.maps.LatLngBounds();
        infowindow.close();
        search_marker.map = null;
        visible_markers = 0;
        var user_region = document.location.pathname.split("/")[1];
        if(getQueryString()['worldwide'] && user_region.toUpperCase() == "INT") {
            $('.int_hq_info').show();
            $('#map').addClass('show_int_hq_info');
        }
        else {
            $('.int_hq_info').hide();
            $('#map').removeClass('show_int_hq_info');
        }
        (window.markers||test_locations).forEach(function (elem) {
            var id = elem.id;
            if(getQueryString()['worldwide'] && elem.type != 'hq'){
                markers[id].setVisible(false);
                return;
            }
            var post = elem.post;
            var companyMatch = false;
            var continentMatch = filter.continent == elem.continent || filter.continent == 'all';
            var countryMatch = filter.country == elem.country_code || (filter.country == 'all');
            var zipMatch = true;
            if (filter.company == 'all') { companyMatch = true } else {
                companyMatch = elem.units.some(function(e) { return e.organization == filter.company});
            }

            if (filter.post) {
                zipMatch = post.search(filter.post) != -1;
                console.log(filter.post + ' / '+ post + "/" + post.search(filter.post));
            }

            if ( countryMatch && continentMatch && companyMatch && zipMatch) {
                bounds.extend(markers[id].getPosition());
                markers[id].setVisible(true);
                visible_markers += 1;
                current_visible_marker = elem;
            }
            else {
                markers[id].setVisible(false);
            }
        });

        if (visible_markers == 1) {
             zoomChangeBoundsListener = google.maps.event.addListenerOnce(map, 'idle', function(event) {
//                if (this.getZoom()){
//                    this.setZoom(7);
//                    if (current_visible_marker) {
//                        map.setCenter(current_visible_marker.coords);
//                    }
//                }
            });
            setTimeout(function(){google.maps.event.removeListener(zoomChangeBoundsListener)}, 2000);
        } else {
            map.fitBounds(bounds);
        }
    }

    function getHQ(country, company) {
        var hq = {};
        (window.markers||test_locations).forEach(function(elem){
            if (elem.country_code == country) {
                elem.units.forEach(function(e){
                    if (e.organization == company && e.cardType == 'hq') {
                        hq.post = elem.post;
                        hq.city = elem.city;
                        hq.street = elem.street;
                        hq.phone = e.phone;
                        hq.fax = e.fax;
                        hq.sub1 = e.sub1;
                        hq.sub2 = e.sub2;
                    }
                });
            }
        });
        if (Object.keys(hq).length != 0) {
            return hq;
        } else {
            return false;
        }

    }

    function getOptions(keyA, valA, keyB) {
        var obj = {};
        (window.markers||test_locations).forEach(function(elem){
            if (keyB == 'units') {
                if (elem[keyA] == valA) {
                    elem[keyB].forEach(function(unit){
                        var str = unit.organization;
                        obj[str] = true;
                    })
                }
            } else {
                if (elem[keyA] == valA) {
                    var str = elem[keyB];
                    obj[str] = true;
                }
            }
        });
        return Object.keys(obj);
    }

    function resetSelect(id){
        $(id + ' option').each(function(){
            if ( $(this).val() != 'all') {
                $(this).remove();
            }
        });
    }

    function hideOptions(id) {
        $(id + ' option').each(function(){
            if ( $(this).val() != 'all') {
                $(this).hide();
            }
        });
    }

    function updateCountry(id, options) {
        hideOptions(id);
        options.forEach(function(val){
            $(id + ' option[value="'+ val +'"]').show();
        });
        $(id).val("all").trigger("chosen:updated");
    }

    function updateSelect(id, options) {
        resetSelect(id);
        options.forEach(function(val){
            $(id).append('<option value="'+ val +'">' + val + '</option>')
        });
        $(id).val("all").trigger("chosen:updated");
    }

    function clickMarker(id) {
        google.maps.event.trigger(markers[id], 'click');
        map.setCenter(markers[id].getPosition());
    }

    //Show all markers
    function showAll(markersArray){
        for (var key in markersArray) {
            markers[key].setVisible(true);
        }
    }

    function hideAll(markersArray){
        for (var key in markersArray) {
            markers[key].setVisible(false);
        }
    }

    var infowindow = new google.maps.InfoWindow();

    var image = {
        url: window.markers ? window.url_map_marker : 'images/map-marker.png',
        size: new google.maps.Size(33, 42),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 40)
    };

    var image_affiliate = {
        url: window.markers ? window.url_map_marker_affiliate : 'images/map-marker_affiliate.png',
        size: new google.maps.Size(33, 42),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 40)
    };

    var image_hq = {
        url: window.markers ? window.url_map_marker_hq : 'images/map-marker_hq.png',
        size: new google.maps.Size(43, 56),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(23, 50)
    };

    var map = new google.maps.Map(document.getElementById('map'), {
        scrollwheel: false,
        zoom: 6,
    });

    var markers = {};
    var search_marker = new google.maps.Marker();
    var init_bounds = new google.maps.LatLngBounds();
    var visible_markers = 0;
    var current_visible_marker;

    //Create markers
    (window.markers||test_locations).forEach(function (elem) {
        geocodeAddress(elem, markers);
    });

    $(document).ready(function () {
        var filtersGroup = $(".locations-filter");
        var locFilter = {};
        var user_region = document.location.pathname.split("/")[1];
        (window.markers||test_locations).forEach(function (elem) {
            var id = elem.id;
            if(user_region.toUpperCase() == "INT" && elem.type != 'hq'){
                markers[id].setVisible(false);
            }
        })

        //Reset all filters
        function reset(){
            filtersGroup.attr('data-companies', 'all').attr('data-countries', 'all').attr('data-continents', 'all');
            $(".locations-filter").removeClass("country-selected").removeClass("continent-selected").removeClass("multiple-company");
            hideOptions('#country');
            resetSelect('#company');
            $("#continent").val("all").trigger("chosen:updated");
            $("#country").val("all").trigger("chosen:updated");
            $("#company").val("all").trigger("chosen:updated");
            updateLocFilter('all', 'all', 'all');
            $("#map-search").val('');
            history.pushState({}, null,'?worldwide=true');
            showAll(markers);
            filterMarkers(locFilter);
            map.panTo(new google.maps.LatLng({lat:20, lng:0}));
            map.setZoom(2);
            $('.locations-container').attr('data-step', 'a');
            $('[data-continent]').show();
            $(".locations-companies").html('');
        }

        function updateLocFilter (a, b, c, d){
            history.replaceState({}, null, window.location.pathname);
            locFilter.continent = a;
            locFilter.country = b;
            locFilter.company = c;
            if (d) {
                locFilter.post = d;
            }
        }

        function setContinent(continent) {
            if (continent != 'all') {
                $('.locations-container').attr('data-step', 'b');
                $('[data-continent]').each(function(){
                    if ($(this).attr('data-continent') != continent ) {
                        $(this).hide()
                    } else {
                        $(this).show();
                    }
                });
                var countryList = getOptions('continent', continent, 'country_code');
                $(".locations-filter").removeClass("country-selected").addClass("continent-selected");
                filtersGroup.attr('data-continents', continent);
                updateLocFilter(continent, 'all', 'all');
                // locFilter.post = '';
                showAll(markers);
                filterMarkers(locFilter);
                // filterCards(locFilter.post);
                updateCountry('#country', countryList);
                if (countryList.length == 1) {
                    $(".locations-filter").removeClass("continent-selected");

                    setCountry(countryList[0]);
                }
            } else {
                $('[data-continent]').show();
                reset();
            }
        }

        function setCountry(country){
            createCards(country);

            var companyList = getOptions('country_code', country, 'units');
            if (country != "all") {
                $('.locations-container').attr('data-step', 'c');
                $(".locations-filter").addClass("country-selected").addClass("multiple-companies");
                updateSelect('#company', companyList);
            } else {
                $('.locations-container').attr('data-step', 'b');
                resetSelect('#company');
                $(".locations-filter").removeClass("country-selected");
            }

            $("#company").val("all").trigger("chosen:updated");
            filtersGroup.attr('data-countries', country);
            updateLocFilter(filtersGroup.attr('data-continents'), country, 'all');
            showAll(markers);
            filterMarkers(locFilter);
            if (country == "DE") {
                $(".map-search").show();
            } else {
                $(".map-search").hide();
            }
            if (companyList.length == 1) {
                $(".locations-filter").removeClass("multiple-companies");
                setCompany(companyList[0])
            }

            // $("#map-search").val('');
            filterMarkers(locFilter);
        }

        function setCompany(company){

            if (company != 'all') {
                $('[data-company]').each(function () {
                    if ($(this).attr('data-company') != company) {
                        $(this).hide()
                    } else {
                        $(this).show();
                    }
                });
            } else {
                $('[data-company]').show();
            }

            $('.locations-container').attr('data-step', 'd');
            filtersGroup.attr('data-companies', company);
            updateLocFilter(filtersGroup.attr('data-continents'), filtersGroup.attr('data-countries'), company);
            showAll(markers);
            filterMarkers(locFilter);
            if (company == 'all') {
                $('.locations-container').attr('data-step', 'c');
            }
        }


        function listcountries(continent) {
            if ( $('[data-continent="'+ continent +'"]').length > 0 ) {
                var list = getOptions('continent', continent, 'country_code');
                var list_countries = getOptions('continent', continent, 'country');
                var cont = $('[data-continent="'+ continent +'"]').find(".content");
                list.forEach(function(e, i){
                    var companies = listcompanies(e);
                    companies.forEach(function(el){
                        var hq = getHQ(e, el);
                        var hq_sub1 = hq.sub1 ? '<h6>' + hq.sub1 + '</h6>' : '';
                        var hq_sub2 = hq.sub2 ? '<h6>' + hq.sub2 + '</h6>' : '';
                        var count = countCompanies(e, el)[0];
                        var hq_str = '';
                        var partnership = countCompanies(e, el)[1] ? 'affiliate' : '';
                        var contents = [];
                        var phone = hq && hq.phone ? '<span class="phone-tab">Tel:</span>' + hq.phone + '<br/>' : '';
                        var fax = hq && hq.fax ? '<span class="phone-tab">Fax:</span>' + hq.fax + '<br/>' : '';
                        if (e) contents.push('<p>'+ list_countries[i] +'</p>');
                        if (countCompanies(e, el)[1]) contents.push('<p class="affiliate">Affiliate</p>');
                        if (typeof count == "string") {
                            contents.push('<p class="hq">' + count + '</p>');
                        }
                        if (el&&el!='undefined') contents.push('<h3>'+ (el) + '</h3>');
                        if (typeof count != "string" && !hq) {
                            var sub1 = count.sub1 ? '<h6>' + count.sub1 + '</h6>' : '';
                            var sub2 = count.sub2 ? '<h6>' + count.sub2 + '</h6>' : '';
                            var street = count.street ? count.street + '<br/>' : '';
                            var post = count.post ? count.post + ' ' : '';
                            var city = count.city ? count.city : '';
                            var ph = count.phone ? '<span class="phone-tab">Tel:</span>' + count.phone + '<br/>' : '';
                            var f = count.fax ? '<span class="phone-tab">Fax:</span>' + count.fax + '<br/>' : '';
                            contents.push(sub1 + sub2 + '<p>' + street + post + city + '</p>' +'<p>' + ph + f + '</p>');
                        }
                        if (hq) contents.push(hq_sub1 + hq_sub2 + '<p>' + hq.street + '<br />' + hq.post + ' ' + hq.city + '</p>' + '<p>' + phone + fax + '</p>');
                        if (hq_str) contents.push(hq_str);
                        cont.append('<div class="col col-s3"><div class="location-card company-group ' + partnership + '"' +' data-filterContinent="'+ continent +'" data-filterCountry="'+ e +'" data-filterCompany="'+ el +'">' + contents.join('') + '</div></div>')
                    })

                });
            }
        }

        function listcompanies(country) {
            return getOptions('country_code', country, 'units');
        }

        function countCompanies(x, y) {
            var counter = 0;
            var onlyLocation = {};
            var isAffiliate = false;
            (window.markers||test_locations).forEach(function(e){
                if (e.country_code == x ) {
                    e.units.forEach(function(n) {
                        if (n.organization == y) {
                            isAffiliate = false;
                            counter ++;
                            onlyLocation.post = e.post;
                            onlyLocation.city = e.city;
                            onlyLocation.street = e.street;
                            onlyLocation.phone = n.phone;
                            onlyLocation.fax = n.fax;
                            onlyLocation.sub1 = n.sub1;
                            onlyLocation.sub2 = n.sub2;
                        }

                        if (n.cardType == 'affiliate') {
                            isAffiliate = true;
                        }
                    })
                }
            });

            if (counter == 1) {
                return [onlyLocation, isAffiliate]
            }

            if (counter > 1) {
                return [counter + '  locations', isAffiliate]
            }
        }



        function createFolders(cl){
            var container = $(".locations-companies");
            container.html('');
            cl.forEach(function(e){
                if ( !e ) return;
                if (current_country=="DE"){
                    container.append('<div class="locations-folder" data-company="'+ e +'"><div class="trigger">'+ e +'</div><div class="content row flex-row"></div></div>')
                } else {
                    container.append('<div class="locations-folder" data-company="'+ e +'"><div class="trigger closed">'+ e +'</div><div class="content row flex-row"></div></div>')
                }

            });
        }

        function createCards(country) {
            var cl = getOptions('country_code', country, 'units');
            var custom_details = false;
            createFolders(cl);
            (window.markers||test_locations).forEach(function(e){
                if (e.country_code == country) {
                    for(var i = 0; i < e.units.length; i++) {
                        if (e.units[i].custom_detail_1 != '' || e.units[i].custom_detail_2 != '') {
                            custom_details = true;
                            break;
                        }
                    }
                    e.units.forEach(function(unit){
                        var folder = $('[data-company="'+ unit.organization +'"]').find(".content");
                        var name='';
                        var sub1 = '';
                        var sub2 = '';
                        var header = '';
                        var address = '';
                        var contacts = '';
                        var type = '';
                        var prefix = '<p class="unit-name">'+unit.typeName+'</p>';
                        var custom_details_1_2 = '';

                        if (unit.sub1) { sub1 = '<p class="gray">' + unit.sub1 + '</p>' }
                        if (unit.sub2) { sub2 = '<p class="gray">' + unit.sub2 + '</p>' }
                        if (unit.name) { name = '<h6>' + unit.name  + '</h6>' }
                        if (unit.name||unit.organization) { header = '<h3>' + (unit.organization || unit.name) + '</h3>' }
                        if (unit.cardType) { type = unit.cardType }
                        if (e.street || e.city) { address = '<p class="gray">' + (e.street + '<br/>' ||'') + ' ' + (e.post + ' ' || '') + e.city + '</p>' }
                        if (unit.phone) {contacts += '<span class="phone-tab">Tel:</span>'+ unit.phone +'<br/>'}
                        if (unit.fax) {contacts += '<span class="phone-tab">Fax:</span>'+ unit.fax +'<br/>'}
                        if (custom_details == true) { custom_details_1_2 = '<p class="gray">' + unit.custom_detail_1 + '&nbsp;<br />' + unit.custom_detail_2 + '&nbsp;</p>'}
                        if (contacts != '') { contacts = '<p class="gray">' + contacts + '</p>'}
                        folder.append('<div class="col col-s3"><div data-post="'+ e.post + '" data-markerId="' + e.id +'" class="location-card ' + type + '">' + prefix + header + name + sub1 + sub2 + address + contacts + custom_details_1_2 + '</div></div>')
                    });
                }
            });
        }

        function searchResult(e, l) {
            var custom_details = false;
            for(var i = 0; i < e.units.length; i++) {
                if (e.units[i].custom_detail_1 != '' || e.units[i].custom_detail_2 != '') {
                    custom_details = true;
                    break;
                }
            }
            e.units.forEach(function(unit){
                var name='';
                var sub1 = '';
                var sub2 = '';
                var header = '';
                var address = '';
                var contacts = '';
                var type = '';
                var prefix = '<p class="unit-name">'+unit.typeName+'</p>';
                var custom_details_1_2 = '';

                if (unit.sub1) { sub1 = '<p class="gray">' + unit.sub1 + '</p>' }
                if (unit.sub2) { sub2 = '<p class="gray">' + unit.sub2 + '</p>' }
                if (unit.name) { name = '<h6>' + unit.name  + '</h6>' }
                if (unit.name||unit.organization) { header = '<h3>' + (unit.organization || unit.name) + '</h3>' }
                if (unit.cardType) { type = unit.cardType }
                if (e.street || e.city) { address = '<p class="gray">' + (e.street + '<br/>' ||'') + ' ' + (e.post + ' ' || '') + e.city + '</p>' }
                if (unit.phone) {contacts += '<span class="phone-tab">Tel:</span>'+ unit.phone +'<br/>'}
                if (unit.fax) {contacts += '<span class="phone-tab">Fax:</span>'+ unit.fax +'<br/>'}
                if (contacts != '') { contacts = '<p class="gray">' + contacts + '</p>'}
                if (custom_details == true) { custom_details_1_2 = '<p class="gray">' + unit.custom_detail_1 + '&nbsp;<br />' + unit.custom_detail_2 + '&nbsp;</p>'}
                l.append('<div class="col col-s3"><div data-post="'+ e.post + '" data-markerId="' + e.id +'" class="location-card ' + type + '">' + prefix + header + name + sub1 + sub2 + address + contacts + custom_details_1_2 + '</div></div>')
            });

        }

        function initCountry(country) {
            var continent = '';
            (window.markers||test_locations).forEach(function(e){
                if (e.country_code == country) {
                    continent = e.continent;
                }

                if (continent != '') {
                    setContinent(continent);
                    $("#continent").val(continent).trigger("chosen:updated");

                    if  (getOptions('continent', continent, 'country').length > 1 ) {
                        setCountry(current_country);
                        $("#country").val(current_country).trigger("chosen:updated");
                    }
                } else return ''
            });
        }

        if (current_country && window.markers.length) {
            initCountry(current_country)
        }
        else {
            reset();
        }

        if ($('#country_of_construction').length > 0 && $('#country_of_construction').val() == 'DE') {
            setContinent('europe');
            setCountry('DE');
        }
        else if ($('#country_of_construction').length > 0 && $('#country_of_construction').val() != 'DE') {
            setContinent('all');
            setCountry('all');
        }

        //Changing continent value
        $('#continent').on("change", function(){
            var val = $(this).val();
            setContinent(val);
        });

        //Changing country value
        $('#country').on("change", function(){
            var val = $(this).val();
            setCountry(val);
        });

        //Contact persons - Change country value if Germany is chosen as country of construction
        $('#country_of_construction').on("change", function(){
            if ($(this).val() == 'DE') {
                setContinent('europe');
                setCountry('DE');
            }
            else {
                setContinent('all');
                setCountry('all');
            }
        })

        //Changing company value
        $('#company').on("change", function(){
            var val = $(this).val();
            setCompany(val);
        });

        $('#reset').click(function(e){
            e.preventDefault();
            reset();
        });

        $(document).on('click', ".company-group", function(){
            $('#continent').val( $(this).attr('data-filterContinent') ).trigger("chosen:updated");
            $('#continent').trigger('change');

            if ( getOptions('continent', $(this).attr('data-filterContinent'), 'country').length > 1 ) {
                setCountry($(this).attr('data-filterCountry'));
                $('#country').val( $(this).attr('data-filterCountry') ).trigger("chosen:updated");
                $('#country').trigger('change');
            }
            if ( getOptions('country', $(this).attr('data-filterCountry'), 'units').length > 1 ) {
                setCompany($(this).attr('data-filterCompany'));
                $('#company').val( $(this).attr('data-filterCompany')).trigger("chosen:updated");
                $('#company').trigger('change');
            }
        });


        $(document).on("click", ".trigger" ,function(){
            $(this).toggleClass("closed");
        });

        $(document).on("click", '[data-markerId]', function() {
            var i = $(this).attr('data-markerId');
            clickMarker(i);
        });

        var continents = {
            'EU': 'europe',
            'NA': 'north_america',
            'SA': 'south_america',
            'ME': 'middle_east',
            'AF': 'africa',
            'OC': 'oceania',
            'AS': 'asia'
        };

        for (var key in continents) {
            listcountries(continents[key]);
        }

        $("#map-search").on("keyup", function(){
            if ( $(this).val() == '' ) {
                $(this).removeClass("changed");
                clearSearch();
            } else {
                $(this).addClass("changed")
            }
        });

        $(".cleaner").click(function(){
            clearSearch();
        });

        window.process_search = function(response) {
            var container = $(".locations-companies"),
                object = response.length > 0 ? response[0] : null;
            container.html('');
            searchResult(object, container);
            hideAll(markers);
            geocodeAddress(object, markers, true);
        };

        if (getQueryString()['worldwide']){
            reset();
        }

        function clearSearch() {
            $("#map-search").val('');
            setCountry(locFilter.country);
            setCompany(locFilter.company);
            $("#map-search").removeClass('changed');
        }
    });
}