$(document).ready(function() {
    $('.preview_anchor').on('click', function(e) {
        e.preventDefault();
        var preview = $(this).next('.newsletter_preview');
        $('.newsletter_preview').not(preview).hide();
        var iframe = preview.find('iframe');
        preview.toggle();
        var content_height = iframe.contents().outerHeight();
        iframe.height(content_height);
    })
})