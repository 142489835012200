function addRows(elem) {
    var tmp = $(elem).find(".template").last();
    var temp = $(tmp).clone(true);
    var rows = $(temp).find(".form-row");
    var groups = $(elem).find(".job-folder-content").find(".form-group");
    var number = groups.length + 1;
    var container = $(elem).find(".job-folder-content").find(".groups");

    $(rows).each(function () {
        var data = $(this).attr("data-for");
        var inputs = $(this).find("input");
        var labels = $(this).find("label");
        var selects = $(this).find("select");
        var validate_dates = $(this).attr('validate-to-id');

        updateAttribute(inputs, "name", data, number);
        updateAttribute(inputs, "id", data, parseInt(number));
        updateAttribute(selects, "name", data, number);
        updateAttribute(selects, "id", data, number);
        updateAttribute(labels, "for", data, number);
        if (validate_dates) {
            var validate_date_for = $(temp).find('#' + validate_dates);

            updateAttribute(this, 'validate-to-id', data, number);
            updateAttribute(validate_date_for, 'id', data, number);
        }

        $(selects).chosen({
            disable_search_threshold: 10,
            width: "100%",
            no_results_text: translations['no_results_text'],
            placeholder_text_single: translations['placeholder_text_single'],
            placeholder_text_multiple: translations['placeholder_text_multiple'],
        });
    });

    $(container).append(temp);
    $(temp).slideDown(500, function(){
        $(temp).removeClass("template").attr("style", false);
        elem.find(".js-remove-last").show();
    });
}

function removeRows(elem) {
    var last_group = $(elem).find(".job-folder-content").find(".form-group").last();
    $(last_group).slideUp(500, function(){
        last_group.remove();
        var number_of_groups = $(elem).find(".job-folder-content").find(".form-group").length;
        if (number_of_groups == 0){
            elem.find(".js-remove-last").hide();
        }
    });
}

function addFileInputs(elem) {
    var tmp = $(elem).find(".template").last();
    var temp = $(tmp).clone(true);
    var groups = $(elem).find(".groups");
    var rows = $(groups).find(".form-row");
    var number = rows.length + 1;
    var inputs = $(temp).find("input");
    var labels = $(temp).find("label");
    var upload = $(temp).find(".file-upload-button");
    updateAttribute(labels, "for", "", number);
    updateAttribute(inputs, "id", "", number);
    $(upload).attr("data-upload", "#file_" + number);

    $(groups).append(temp);
    $(temp).slideDown(500, function(){
        $(temp).removeClass("template").attr("style", false);
        elem.find(".js-remove-inputs").show();
    });
}

function removeFileInputs(elem) {
    var last_group = $(elem).find(".groups").find(".form-row").last();
    $(last_group).slideUp(500, function(){
        last_group.remove();
        var number_of_groups = $(elem).find(".groups").find(".form-row").length;
        if (number_of_groups == 0){
            elem.find(".js-remove-inputs").hide();
        }
    });
}

function updateAttribute(elems, attr, value, num) {
    $(elems).each(function () {
        if ( $(this).attr(attr) ){
            var prev = $(this).attr(attr);
            $(this).attr(attr, value + prev + num);
        }
    });
}

function addTableRows(elem) {
    var tmp = $(elem).find(".template").last();
    var temp = $(tmp).clone(true);
    // var rows = $(temp).find(".form-row");
    var groups = $(elem).find(".users-table").find(".form-row");
    var number = groups.length + 1;
    var container = $(elem).find(".users-table");

    $(temp).each(function () {
        var labels = $(this).find("label");
        var inputs = $(this).find("input");
        updateAttribute(inputs, "name", "", number);
        updateAttribute(inputs, "id", "", number);
        updateAttribute(labels, "for", "", number);
    });

    $(container).append(temp);
    $(temp).slideDown(500, function(){
        $(temp).removeClass("template").attr("style", false);
        elem.find(".js-remove-table").show();
    });
}

function removeTableRows(elem) {
    var last_group = $(elem).find(".users-table").find(".form-row").last();
    $(last_group).slideUp(500, function(){
        last_group.remove();
        var number_of_groups = $(elem).find(".users-table").find(".form-row").length;
        if (number_of_groups == 0){
            elem.find(".js-remove-table").hide();
        }
    });
}

$(".js-add-new").click(function (e) {
    e.preventDefault();
    addRows( $(this).closest(".job-folder") );
});

$(".js-remove-last").click(function (e) {
    e.preventDefault();
    removeRows( $(this).closest(".job-folder") );
});


$(".js-add-inputs").click(function (e) {
    e.preventDefault();
    addFileInputs( $(this).closest(".form-panel") );
});

$(".js-remove-inputs").click(function (e) {
    e.preventDefault();
    removeFileInputs( $(this).closest(".form-panel") );
});


$(".js-add-table").click(function (e){
    e.preventDefault();
    addTableRows( $(this).closest(".job-folder") );
});

$(".js-remove-table").click(function (e){
    e.preventDefault();
    removeTableRows( $(this).closest(".job-folder") );
});