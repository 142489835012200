document.addEventListener('DOMContentLoaded', function(){
    var privacy_settings_modal = $('#privacy-settings-modal');
    var privacy_settings_button = $('.privacy-settings-link');
    var privacy_settings_close_button = $('.modal-settings-close');
    var analytics_selector = $('#analytics-setting');
    var privacy_setting_analytics_cookie = getCookie('privacy-setting') || 'allow';
    var privacy_settings_next_page = $('.privacy-settings-next-page');
    var cc_globalpreference_selector_social = $('#cc-globalpreference-selector-social');
    var cc_globalpreference_selector_analytics = $('#cc-globalpreference-selector-analytics');
    var cc_globalpreference_selector_advertising = $('#cc-globalpreference-selector-advertising');
    var wiredminds_optout = $(".wiredminds-opt-out");

    wiredminds_optout.on('click', function() {
        alert_text = gettext('You are now excluded from tracking.');
        alert(alert_text);
    });

    privacy_settings_next_page.on('click', function(e) {
        e.preventDefault();
        $('#privacy-website-settings').fadeToggle(300);
        $('#privacy-global-settings').fadeToggle(300);
    });

    $(cc_globalpreference_selector_social)
    .add(cc_globalpreference_selector_analytics)
    .add(cc_globalpreference_selector_advertising).on('change', function(e) {
        setCookie('cc_' + $(this).attr('name'), $(this).val());
    })

    cc_globalpreference_selector_social.val(getCookie('cc_' + cc_globalpreference_selector_social.attr('name')) || 'ask')
    cc_globalpreference_selector_analytics.val(getCookie('cc_' + cc_globalpreference_selector_analytics.attr('name')) || 'ask')
    cc_globalpreference_selector_advertising.val(getCookie('cc_' + cc_globalpreference_selector_advertising.attr('name')) || 'ask')

    privacy_settings_button.on('click', function(e) {
        e.preventDefault();
        privacy_settings_modal.fadeIn(300);
        var defaultValue = getCookie('privacy-setting') || privacy_setting_analytics_cookie;
        $("#analytics-setting").val(defaultValue).change();
        $("#analytics-setting").trigger("chosen:updated");
    });

    privacy_settings_modal.on('click', function(e) {
        if (e.target !== this)
            return;

        $(this).fadeOut(400);
    });

    privacy_settings_close_button.on('click', function(e) {
        e.preventDefault();
        $(privacy_settings_modal).fadeOut(300);
    });

    analytics_selector.val(privacy_setting_analytics_cookie);

    analytics_selector.on('change', function(e) {
        setCookie('privacy-setting', $(this).val());
    });

 })
