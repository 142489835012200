/**
 * Created by Alexander on 27.07.2016.
 */
function equalHeights(elem) {
    var viewport = $(window).width();
    $(elem).attr("style", false);

    if (viewport > 1024) {
        var a = $(elem).eq(0).height();
        var b = $(elem).eq(1).height();

        if (a >= b) {
            $(elem).css("min-height", a + "px");
        } else {
            $(elem).css("min-height", b  + "px");
        }
    }

}


// $(document).ready(function () {
//     $(".row").each(function () {
//         var columns = $(this).find(".js-text-frame");
//         equalHeights(columns);
//     });
//
//     $(window).resize(function () {
//
//             $(".row").each(function () {
//                 var columns = $(this).find(".js-text-frame");
//                 equalHeights(columns);
//             });
//
//     });
// });