$(document).ready(function () {
    // Highlight current section
    var slug = document.location.pathname.split('/').slice(3, 4)[0] || 'not-selected';
    if (slug == 'news-and-events' && getQueryString()['type'] != undefined) {
        var q_string = window.location.search.substring(1);
        var single_type_filter = q_string.indexOf('type') == q_string.lastIndexOf('type');
        var careers_type_id = $('.submenu.'+slug).find('[data-careers]').attr('data-careers');
        if (single_type_filter && getQueryString()['type'] == careers_type_id) {
            slug = 'people';
        }
    }
    $('[data-active="' + slug + '"]').addClass('selected');
    if ( $('.selected').length != 0 ) {
        $('.selected').addClass('active');
        $('.main-menu').addClass('has-selected');
    }

    function closeMenu(){
        $(".main-menu").attr("style", false);
        $(".main-menu-opener").removeClass("opened");
    }

    function openMenu(isLandingPage, target, focus, item_type) {
        clearTimeouts();
        var category_pages = ['technologies', 'solutions', 'services']
        if (!isLandingPage || category_pages.includes(item_type)) {
            $(target).slideDown(300, function(){
                if (focus) {
                    $(focus).focus();
                }
                if (!window.isMobile || !($('.main-menu-opener').css('display') != 'none')){
                    $(target).find('.submenu-content').css('overflow-y', 'auto');
                    $(target).find('.menu-l2').css('overflow-y', 'auto');
                }
            });
        }
    }

    function disableScroll() {
        $("body").addClass('scroll-disabled');
    }

    function enableScroll() {
        $("body").removeClass('scroll-disabled');
    }

    function closeMenu(target) {
        clearTimeouts();
        $('.submenu-content').each(function () {
            $(this).css('overflow-y', 'hidden');
        });
        $('.menu-l2').each(function () {
            $(this).css('overflow-y', 'hidden');
        });
        $(target).slideUp(300);
    }

    $(".header-service-link").click(closeMenu);
    $(".header-service-search").click(closeMenu);

    var trigger = $(".js-trigger");
    var languageTrigger = $(".js-lang-trigger");
    var modal = $(".js-modal");
    var menuOpener = $(".main-menu-opener");
    var menu = $(".main-menu");
    var regionSearchInput = $("#header-search-field");

    regionSearchInput.keyup(function() {
        var valThis = $(this).val().toLowerCase();
        if(valThis == ""){
            $('.sites-list > li').show();
        } else {
            $('.sites-list > li').each(function(){
                var text = $(this).text().toLowerCase();
                (text.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
            });
       };
    })

    //Search, Language select and Submenu modal scripts

    languageTrigger.on("click", function(e){
        e.preventDefault();
        e.stopPropagation();
        var target = $(this).data("toggle");
        var isActive = $(this).hasClass("active");
        var isSelected = $(this).hasClass("selected");
        var focus = $(this).data("focus");
        languageTrigger.removeClass("active");
        modal.hide();

        if (!isActive || isSelected) {
            $(target).slideDown(300, function(){
                if (focus) {
                    $(focus).focus();
                }
            });
            $(this).addClass("active");
        }
        if (isActive && !isSelected) {
            $(this).removeClass("active");
        }
    });

    trigger.on('touchstart', function(e) {
        trigger.not('.active').addClass('non-touch');
    });

    trigger.on("click", function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).removeClass('non-touch');
        var target = $(this).data("toggle");
        var isActive = $(this).hasClass("active");
        var isSelected = $(this).hasClass("selected");
        var focus = $(this).data('focus');
        var item_type = $(e.currentTarget).data('active');
        var isLandingPage = $(e.currentTarget).data("landingpage") == "True";

        // trigger.not('[data-active="' + slug + '"]').removeClass("active");
        trigger.not(this).removeClass("active");
        if ( $(this).hasClass(".main-menu-item") ) {
            $(".selected").addClass('active');
        }

        if ((window.isMobile || $('.main-menu-opener').css('display') != 'none') && $(target).css('display') != 'none') {
            $(target).slideUp(300);
            $(target).find('.active').removeClass('active');
            $(this).removeClass('active');
            $(this).addClass('non-touch');
        } else {
            if(!isActive)
                modal.hide();

            if (!isActive || isSelected) {
                openMenu(isLandingPage, target, focus, item_type);
                languageTrigger.removeClass("active");
                $(".selected").removeClass("active");
                $(this).addClass("active");
            }

            else if (isActive || $('.main-menu-opener').attr('display') != 'none') {
                $(this).removeClass("active");
            }
        }
    });

    $('.pre-head').hover(function (e) {
        clearTimeouts();
        $('.submenu').slideUp(300);
    });

    $(document).on('click', function(e) {
        if(touchDevice && window.innerWidth > 1024 && $('.submenu').is(':visible') && $('.submenu').find(e.target).length == 0) {
         $(".main-menu-item.selected").addClass('active');
         $(".main-menu-item").not('.selected').addClass('non-touch').removeClass('active');
         $('.submenu').slideUp(300);
       }
    })

    if(!touchDevice) {
    //Remove "active" state from currently selected item when hovers another one
        $(".main-menu-item").hover(function (e) {
            $(".main-menu").addClass("no-active-decoration");
            e.preventDefault();
            e.stopPropagation();
            $("select").trigger("chosen:close");
            if (window.isMobile || $('.main-menu-opener').css('display') != 'none')
                return;
            var target = $(e.currentTarget).data("toggle");
            var isActive = $(e.currentTarget).hasClass("active");
            var isSelected = $(e.currentTarget).hasClass("selected");
            var isLandingPage = $(e.currentTarget).data("landingpage") == "True";
            var item_type = $(e.currentTarget).data('active');
            var focus = $(e.currentTarget).data('focus');
            var openedSubmenu = $('.js-modal').filter(function() {
                return $(this).css('display') == 'block';
            }).eq(0);
            // trigger.not('[data-active="' + slug + '"]').removeClass("active");

            if ( $(e.currentTarget).hasClass('.main-menu-item') ) {
                $(".selected").addClass('active');
            }

            $(e.currentTarget).on('mouseleave', function(e) {
                if ($(e.relatedTarget).data('active') != this.id && ((e.relatedTarget && e.relatedTarget.id != $(target)[0].id) || e.relatedTarget == null)) {
                    enableScroll();
                    $(e.currentTarget).removeClass("active");
                    $(".selected").addClass('active');
                    if (!$(e.relatedTarget).hasClass('main-menu-item')) {
                        closeMenu(target);
                    }
                }
            });

            $(target).on('mouseleave', function(e) {
                enableScroll();
                if(e.relatedTarget != null && $(e.relatedTarget).data('active') != this.id) {
                    if (!$(e.relatedTarget).hasClass('main-menu-item')) {
                        closeMenu(this);
                    }
                    trigger.removeClass("active");
                    $(".selected").addClass('active');
                }
            });

            $(target).on('mouseenter', function(e) {
                $('.main-menu-item').filter(function() {
                    return $(this).hasClass($(e.currentTarget)[0].id);
                }).eq(0).addClass('active');
                disableScroll();
            });

            clearTimeouts();
            window.timeouts.push(
                setTimeout(function() {
                    if (!isActive) {
                        trigger.removeClass("active");
                        languageTrigger.removeClass("active");
                        $(e.currentTarget).removeClass("active");
                    }
                    if (!isActive || isSelected) {
                        if (!$(target).is(':visible')){
                            if (openedSubmenu.length){
                                closeMenu(openedSubmenu);
                                setTimeout(function(){
                                    openMenu(isLandingPage, target, focus, item_type);
                                }, 300);
                            } else {
                                openMenu(isLandingPage, target, focus, item_type);
                            }
                        }
                        $(".selected").removeClass('active');
                        $(e.currentTarget).addClass("active");
                    }
                }, 300)
            );

        },
        function () {
            $(".main-menu").removeClass("no-active-decoration");
        });

    }

    //Main menu modal script

    menuOpener.click(function () {

        trigger.removeClass("active");
        modal.css("display", 'none');
        $(this).toggleClass("opened");
        $(".main-menu-item.selected").addClass('active');


        var isVisible = menu.css("display") == "block";
        if (!isVisible) {
            menu.slideDown(500);
        } else {
            menu.css("display", 'none');
        }
    });

    $(".menu-l2-item > a").click(function(e){
        if (!$(this).hasClass("no-submenu") && $(this).hasClass('no_landing_page')){
            e.preventDefault();
        }
        var isActive = $(this).parent().hasClass("active");
        $(".menu-l2-item").removeClass("active");
        if(!isActive) { $(this).parent().addClass("active"); }
        if(touchDevice && window.innerWidth > 1024){
           $(this).parent().addClass('hover');
           $(this).parent().siblings().removeClass('hover');
        }
        else if(touchDevice && window.innerWidth <= 1024) {
            $(".menu-l2-item.hover").removeClass("hover");
        }

    });

    // Not in range clicks

    function hideOutranged(elem, elemToggler, state){
        $(document).mouseup(function (e){
            if (e.relatedTarget != null && !elem.is(e.target) && elem.has(e.target).length === 0 && !elemToggler.is(e.target) && elemToggler.has(e.target).length === 0) {
                if (window.timeouts.length)
                    return;
                if (elem.hasClass('main-menu') && window.isMobile)
                    elem.css("display", 'none');
                elemToggler.removeClass(state);
                $(".selected").addClass("active");
            }
        });
    }

    hideOutranged(menu, menuOpener, "opened");
    hideOutranged(modal, trigger, "active");
    hideOutranged(modal, languageTrigger, "active");

    // No modal lang switch
    $(".header-lang-name").click(function(){
        $(".header-lang-name").removeClass("active");
        $(this).addClass("active")
    });

    function hoveredMenu(){
        var menu = $(".menu-l2");
        var item = $(".menu-l2-item");
        var mouse_x;
        var isMovingRight;

        // Init mouse moving watch
        menu.on('mouseenter', function(e){
            mouse_x = e.pageX;
        });

        // Reset all hovers when cursor leaving menu
        menu.on('mouseleave', function(e){
            if (e.relatedTarget != null) {
                $(".hover").removeClass('hover');
            }
        });

        // Updating cursor positioning
        menu.on('mousemove', function(e){
            isMovingRight = e.pageX > mouse_x;
            mouse_x = e.pageX;
        });

        // Hovers item if no hovered elements
        item.on('mouseenter', function(){
            if (!touchDevice && window.innerWidth > 1024 && $(".hover").length == 0 ) {
                $(this).addClass('hover');
            }
        });

        // Hovers item if there is already hovered item and cursor stop moving left
        item.on('mousemove', function(){
            if (!touchDevice && window.innerWidth > 1024 && !isMovingRight && !$(this).hasClass('hover') ) {
                item.removeClass('hover');
                $(this).addClass('hover');
            }
        });

        // Remove hover statement from element on leaving cursor
        item.on('mouseleave', function(){
            var hasInnerMenu = $(this).find('.menu-l3').length > 0;
            var hoveredAlready = $(this).hasClass('hover');
            if (!touchDevice && window.innerWidth > 1024 && hoveredAlready && !isMovingRight || !hasInnerMenu) {
                $(this).removeClass('hover');
            }
        });
    }

    hoveredMenu();



});