document.addEventListener('DOMContentLoaded', function(){
    var fields = {
        language_code: document.getElementById('language_code'),
        region_code: document.getElementById('region_code'),
        keyword: document.getElementById('js-search'),
        areas: document.getElementById('js-areas'),
        position: document.getElementById('js-position'),
        organization: document.getElementById('js-organization'),
        location: document.getElementById('js-location'),
        postcode: document.getElementById('js-postcode'),
        radius: document.getElementById('js-radius')
    };

    var options = {
        results: document.getElementById('js-result'),
        submit: document.getElementById('js-submit'),
        reset: document.getElementById('js-reset'),
        reload: document.querySelector('[data-pajax]')
    };

    window.job_controller = new JobFilter(fields, options);
    if (window.job_controller.results_element) {
        setTimeout(function(){
            window.job_controller.change(1,1);
        }, 100);
    }
});


/**
 * JobFilter Class
 * @param fields
 * @param options
 * @constructor
 */
function JobFilter(fields, options)
{
    var self = this;

    // Defining filter fields
    this.fields = fields;

    // Results count element
    this.results_element = options.result || document.getElementById('js-result');

    // Submit button
    this.submit_button = options.submit || document.getElementById('js-submit');

    // Reset button
    this.reset_button = options.reset || document.getElementById('js-reset');
    if ( this.reset_button ) {
        this.reset_button.addEventListener('click', function(){
            self.clear_form();
        });
    }

    // Data AJAX-Placeholder
    this.data_placeholder = options.reload || document.getElementById('data-placeholder');

    // Listening for changes
    var keys = Object.keys(this.fields);
    for ( var i = 0; i < keys.length; i++ ) {
        var key = keys[i],
            $el = this.fields[key];

        if ($el !== null) {
            var timeout = null;
            if (key === 'keyword') {
                $el.oninput = function (e) {
                    if (timeout !== null) {
                        clearTimeout(timeout);
                    }
                    timeout = setTimeout(function () {
                        self.change(e, self.data());
                    }, 500);
                }
            } else {
                $el.onchange = function(e){
                    self.change(e, self.data());
                };
            }
        }
    }
}

/**
 * Returns form data
 * @param additional
 * @returns {*}
 */
JobFilter.prototype.form = function(additional)
{
    var data = this.data();
    if ( additional ) {
        for ( var z = 0; z < Object.keys(additional).length; z++ ) {
            data[Object.keys(additional)[z]] = additional[Object.keys(additional)[z]];
        }
    }

    var form = new FormData(),
        keys = Object.keys(data);

    for ( var i = 0; i < keys.length; i++ ) {
        if ( Array.isArray(data[keys[i]]) ) {
            for ( var k = 0; k < data[keys[i]].length; k++ ) {
                form.append(keys[i], data[keys[i]][k]);
            }
        } else {
            form.append(keys[i], data[keys[i]]);
        }
    }

    return form;
};

/**
 * Creates query string
 * @param additional
 * @returns {string}
 */
JobFilter.prototype.query = function(additional)
{
    var data = this.data();
    if ( additional ) {
        for ( var z = 0; z < Object.keys(additional).length; z++ ) {
            data[Object.keys(additional)[z]] = additional[Object.keys(additional)[z]];
        }
    }

    var items = [],
        keys = Object.keys(data);

    for ( var i = 0; i < keys.length; i++ ) {
        if ( Array.isArray(data[keys[i]]) ) {
            items.push(keys[i] + '=' + encodeURIComponent(data[keys[i]].join()));
        } else {
            items.push(keys[i] + '=' + encodeURIComponent(data[keys[i]]));
        }
    }

    return items.join('&')
};

/**
 * Sets results number
 * @param number
 */
JobFilter.prototype.set_results_number = function(number)
{
    this.results_element.innerText = number.toString();
};

/**
 * Clear form
 */
JobFilter.prototype.clear_form = function()
{
    var self = this;
    setTimeout(function(){
        self.change(1,1);
    }, 100);
};

/**
 * Disable button submit
 */
JobFilter.prototype.disable_submit = function()
{
    if ( this.submit_button ) {
        this.submit_button.classList.add('disabled');
        this.submit_button.setAttribute('disabled', true);
    }
};

/**
 * Enable button submit
 */
JobFilter.prototype.enable_submit = function()
{
    if ( this.submit_button ) {
        this.submit_button.classList.remove('disabled');
        this.submit_button.removeAttribute('disabled');
    }
};

/**
 * Reload with new filter data
 */
JobFilter.prototype.reload = function()
{
    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', document.location.pathname + '?' + this.query({'ajax': 'true', 'search': 'all'}), true);

    // Result processing
    xhr.onload = function() {
        self.data_placeholder.innerHTML = this.responseText;
        history.replaceState({}, null, '?' + self.query({'search': 'all'}));
    };

    xhr.send();
};

/**
 * Loading page
 * @param page
 * @param extra
 */
JobFilter.prototype.load_page = function(page, extra)
{
    extra = extra || {};
    extra['ajax'] = 'true';
    extra['search'] = 'all';
    extra['page'] = parseInt(page);

    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open('GET', document.location.pathname + '?' + this.query(extra), true);

    // Result processing
    xhr.onload = function() {
        self.data_placeholder.innerHTML = this.responseText;
    };

    xhr.send();

    return false;
};

/**
 * On any field changed
 */
JobFilter.prototype.change = function(evt, data)
{
    var self = this;
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/job-adverts/count', true);

    // Result process
    xhr.onload = function() {
        var jobs_number = parseInt(this.responseText);

        if ( jobs_number < 1 ) {
            self.disable_submit();
        } else {
            self.enable_submit();
        }

        self.set_results_number(this.responseText);

        // Reloading data
        if ( self.data_placeholder ) {
            self.reload();
        }
    };

    xhr.send(this.form());
};

/**
 * Returns fields data
 * @returns {{}}
 */
JobFilter.prototype.data = function()
{
    var data = {},
        keys = Object.keys(this.fields);

    for ( var i = 0; i < keys.length; i++ ) {
        var key = keys[i],
            $el = this.fields[key];
        if ($el){
            switch ($el.tagName) {
                case 'SELECT':
                    data[key] = [];
                    var values = $el.querySelectorAll('option');
                    for ( var k = 0; k < values.length; k++ ) {
                        if ( values[k].selected ) {
                            data[key].push(values[k].getAttribute('value'));
                        }
                    }
                    break;
                default:
                    data[key] = $el.value;
            }
        }
    }

    return data;
};