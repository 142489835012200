function initPromoMap() {

    function createTooltip(obj) {
        var string ='';
        var lat = obj.coords.lat;
        var lng = obj.coords.lng;
        obj.units.forEach(function (unit) {
            var marker_phone = '';
            var marker_fax = '';
            var marker_header = '<h5>' + unit.organization + '</h5>';
            var marker_street = obj["street"] + '<br/>';
            var marker_city = obj["post"] + ' ' + obj["city"] + '</br>';
            if (unit["phone"]) marker_phone = '<span class="phone-tab">Tel:</span>' + unit["phone"] + '</br>';
            if (unit["fax"]) marker_fax = '<span class="phone-tab">Fax:</span>' + unit["fax"] + '</br>';
            var marker_subhead = "";
            if (unit["name"]) {
                marker_subhead = '<h6>' + unit["name"] + '</h6>';
            }
            var directions_link = '<div class="directions_button_container">' +
            '<a target="_blank" class="btn cta_button" href="https://www.google.com/maps/place/'+lat+','+lng+'">Directions</a></div>'
            string += '<div>' + marker_header + marker_subhead + '<div class="tooltip-addr">' + marker_street + marker_city+ '</div>' + marker_phone + marker_fax + directions_link + '</div>'
        });

        return '<div class="marker">' + string + '</div>';

    }

    //Get coordinates from marker and create marker
    function geocodeAddress(obj, markers_array, from_search) {
        var info = createTooltip(obj);
        map.setCenter(obj.coords);
        var mapImage = image;
        var type = obj.type;

        var marker = new google.maps.Marker({
            id: obj.id,
            map: map,
            icon: mapImage,
            position: obj.coords
        });

        if (markers_array) {
            markers_array[obj.id] = marker;
            infowindow.setContent(info);
        }
        else {
            search_marker = marker;
        }

        marker.addListener('click', function () {
            infowindow.setContent(info);
            infowindow.open(map, marker);
        });
    }

    function clickMarker(id) {
        google.maps.event.trigger(markers[id], 'click');
        map.setCenter(markers[id].getPosition());
    }

    //Show all markers
    function showAll(markersArray){
        for (var key in markersArray) {
            markers[key].setVisible(true);
        }
    }

    function hideAll(markersArray){
        for (var key in markersArray) {
            markers[key].setVisible(false);
        }
    }

    var infowindow = new google.maps.InfoWindow();
    var image = {
        url: window.markers ? window.url_map_marker : 'images/map-marker.png',
        size: new google.maps.Size(33, 42),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 40)
    };

    var map = new google.maps.Map(document.getElementById('map'), {
        scrollwheel: false,
        zoom: 6,
    });

    var markers = {};
    var search_marker = new google.maps.Marker();
    var init_bounds = new google.maps.LatLngBounds();
    var visible_markers = 0;
    var current_visible_marker;

    //Create markers
    (window.markers||test_locations).forEach(function (elem) {
        geocodeAddress(elem, markers);
    });

    $(document).ready(function () {
        (window.markers||test_locations).forEach(function (elem) {
            var id = elem.id;

        $(document).on("click", ".trigger" ,function(){
            $(this).toggleClass("closed");
        });

        $(document).on("click", '[data-markerId]', function() {
            var i = $(this).attr('data-markerId');
            clickMarker(i);
        });

        });

    });
}