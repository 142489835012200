var timepicker_options = {
    timeFormat: 'H:mm',
    interval: 30,
    minTime: '9:00',
    maxTime: '18:00',
    dynamic: false,
    dropdown: true,
    scrollbar: false,
    zindex: 1000
}

var datepicker_options = {
    dateFormat: "yy-mm-dd",
    firstDay: 1,
    minDate:1,
    beforeShowDay: $.datepicker.noWeekends,
};
$(document).ready(function() {
    var price_units = 'ct/kWh';
    $("#electricity_price_val").html($("#electricity_price_range").val() + ' ' + price_units);
    $("#gas_price_val").html($("#gas_price_range").val() + ' ' + price_units);

    if (language_code == 'de') {
        datepicker_options.monthNames = ['Januar','Februar','März','April','Mai','Juni',
        'Juli','August','September','Oktober','November','Dezember'];
        datepicker_options.dayNamesMin = ['So','Mo','Di','Mi','Do','Fr','Sa'];
        datepicker_options.prevText = 'Zurück';
        datepicker_options.nextText = 'Vor';
    }

    $('input[name=meeting_time_from_1], input[name=meeting_time_to_1]').timepicker(timepicker_options);
        $('input[name=meeting_date_1]').datepicker(datepicker_options);

        /* Prevent the user from typing dates in instead of using the date picker */
        $('input.timepicker, input.datepicker').keydown(function(e) {
          e.preventDefault();
    });

    if (typeof gage_min_value != "undefined" && typeof gage_max_value != "undefined" && typeof thresholds != "undefined") {
        var gauge = new JustGage({
                id: "gauge", // the id of the html element
                min: gage_min_value,
                max: gage_max_value,
                hideMinMax: true,
                decimals: 2,
                gaugeWidthScale: 0.6,
                relativeGaugeSize: true,
                pointer: true,
                textRenderer: function (val) {
                    if (val < thresholds[1]['lo']) {
                        return thresholds[0]['res_label'];
                    } else if (thresholds[1]['lo'] <= val && val <= thresholds[1]['hi']) {
                        return thresholds[1]['res_label'];
                    } else if (thresholds[2]['lo'] <= val && val <= thresholds[2]['hi']) {
                        return thresholds[2]['res_label'];
                    }
                    else if (thresholds[3]['lo'] <= val && val <= thresholds[3]['hi']) {
                        return thresholds[3]['res_label'];
                    }
                    else if (val >= thresholds[4]['lo']) {
                        return thresholds[4]['res_label'];
                    }
                },
                customSectors: {
                    ranges: thresholds,
              },
        });
    }

    $('#heating_demand, #fire_protection').on('change', function(){
        $(this.parentElement).removeClass('invalid');
    });

    $('.range_slider').on('input change',function(){
        var output = $(this).next('span');
        var off = this.offsetWidth / (this.max - this.min);
        var px = ((this.valueAsNumber - this.min) * off) - (output[0].offsetWidth / 2);
        var low_threshold_end = 3.1;
        var high_threshold_start = 3.9;
        if ($(this).hasClass('electricity_slider')) {
            low_threshold_end = 14;
            high_threshold_start = 16;
        }
        if (px < -15) {
            px = -15;
        }
        output.html(this.value + ' ' + price_units);
        output.css('left',px+'px');
        var slider_colour = 'green';
        if (this.value > high_threshold_start) {
            slider_colour = 'red';
        }
        else if (this.value > low_threshold_end && this.value <= high_threshold_start) {
            slider_colour = 'orange';
        }
        $(this).attr('data-colour', slider_colour);
        var percent_progress = ((this.value-this.min)/(this.max-this.min))*100;
        this.style.background = 'linear-gradient(to right, '+slider_colour+' 0%, '+slider_colour+' '+ percent_progress + '%, #e6e6e6 ' + percent_progress + '%, #e6e6e6 100%)'

    });

    $("#btn_calculate").on('click', function (e){
        e.preventDefault();
        var gas_price = $('#gas_price_range').val();
        var max_gas_price = $('#gas_price_range').attr('max');
        var electricity_price = $('#electricity_price_range').val();
        var max_electricity_price = $('#electricity_price_range').attr('max');
        var heating_demand = $('#heating_demand').val();
        var fire_protection = $('#fire_protection').val();
        if (!fire_protection) {
            $('#fire_protection').parents('.cost_benefit_dropdown').addClass('invalid');
        }
        if (!heating_demand) {
            $('#heating_demand').parents('.cost_benefit_dropdown').addClass('invalid');
        }
        if (gas_price && electricity_price && heating_demand && fire_protection) {
            $(".cost_benefit_results").show();
            var size_factor = $('#fire_protection>option:selected').attr('data-size');
            var duration_factor = $('#fire_protection>option:selected').attr('data-duration');
            var gas_cost = gas_price*250*8640;
            var max_gas_cost = max_gas_price*250*8640;
            var power_yield = electricity_price*100*8640;
            var max_power_yield = max_electricity_price*250*8640;
            var heat_yield = (gas_price/0.9)*100*8640*heating_demand;
            var max_heat_yield = (max_gas_price/0.9)*100*8640;
            var running_costs = size_factor*duration_factor*electricity_price*365;
            var max_running_costs = 65*17*max_electricity_price*365;
            var animation_duration = 2000;
            var progress_bars = [
                {
                    bar_el: $('.progress_bar.bar_gas'),
                    value: gas_cost/100,
                    width: ((gas_cost/max_gas_cost)*100+'%'),
                    sign: '€',
                },
                {
                    bar_el: $('.progress_bar.bar_power'),
                    value: power_yield/100,
                    width: ((power_yield/max_power_yield)*100+'%'),
                    sign: '€'
                },
                {
                    bar_el: $('.progress_bar.bar_heat'),
                    value: heat_yield/100,
                    width: ((heat_yield/max_heat_yield)*100+'%'),
                    sign: '€'
                },
                {
                    bar_el: $('.tab_conventional_methods .progress_bar.bar_costs'),
                    value: running_costs/100,
                    width: ((running_costs/max_running_costs)*100+'%'),
                    sign: '€'
                },
                {
                    bar_el: $('.tab_fuel_cell .progress_bar.bar_costs'),
                    value: -(running_costs/100),
                    width: ((running_costs/max_running_costs)*100+'%'),
                    sign: '€'
                },
                {
                    bar_el: $('.tab_conventional_methods .progress_bar.bar_co'),
                    value: 40,
                    width: '60%',
                    sign: '%',
                    before_val: '+'
                },
                {
                    bar_el: $('.tab_fuel_cell .progress_bar.bar_co'),
                    value: -40,
                    width: '30%',
                    sign: '%'
                },
            ]
            progress_bars.forEach(function (bar) {
                var value_el = bar.bar_el.find('span.progress_value');
                var before_val = bar.before_val ? bar.before_val : '';
                bar.bar_el.animate({width: bar.width}, animation_duration);
                animateIncrement(convertToNumber(value_el.html()), bar.value, animation_duration, value_el, bar.sign, before_val);
            });
            var end_result = (power_yield+heat_yield+running_costs-gas_cost)/100;
            if (typeof gauge != "undefined") {
                gauge.refresh(end_result);
            }
            var results_container = $('.cost_benefit_results')[0];
            results_container.scrollIntoView({behavior: "smooth"});

            data = {
                gas_price: gas_price,
                electricity_price: electricity_price,
                heating_demand: heating_demand,
                fire_protection_requirement: fire_protection,
                end_result: end_result
            }

            $.ajax({
                type: 'POST',
                url: document.location.href,
                data: data,
                headers: {
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            })
        }
    });

   function convertToNumber(str){
        return Number(str.replace(/[^0-9-]+/g,""));
   }

   function commaSeparateNumber(val){
        while (/(\d+)(\d{3})/.test(val.toString())){
          val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
        }
        return val;
   }

   function animateIncrement(start,stop,duration,el,sign,before_val) {
        $({progress_val: start}).animate({progress_val: stop}, {
              duration: duration,
              easing:'swing',
              step: function() {
                  el.text(before_val+commaSeparateNumber(Math.round(this.progress_val))+sign);
              },
              complete: function() {
                el.text(before_val+commaSeparateNumber(Math.round(this.progress_val))+sign);
              }
          });

   }
})
