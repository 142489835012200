document.addEventListener('DOMContentLoaded', function () {
    // make embeded videos responsive, centered and the width according to the django setting
    $("p").has("iframe").not('.video-container').addClass('video-container');


    $('[data-pin-after]').each(function () {
        var $e = $(this);

        // Hide before it can be rendered right
        $e.hide();

        // Get the pin target
        var $target = $($e.attr('data-pin-after'));
        if ( !$target.length ) {
            return $e.show();
        }

        // Calculate margins
        var $margin = $target[0].getClientRects()[0];

        // Configure margins
        $e.css({marginTop: $margin.height});

        // If image floating left then we must add margin to the first list element
        $e.on('load', function () {
            var $first_ul = $($target.parent().find('ul')[0]);
            if ( $e.parent().css('float') == 'left' && $first_ul ) {
                $first_ul.css('margin-left', $e[0].getClientRects()[0].width);
            }
        });

        // Display element
        $e.show();
    });
});