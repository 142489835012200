document.addEventListener('DOMContentLoaded', function () {
    $('.special-docs-form #id_accept_terms').prop('checked', false);
    $('.special-docs-submit-btn').addClass('disabled').attr('disabled', true);
    $('.special-docs-form #id_accept_terms').on('change', function () {
        if ($('.special-docs-form #id_accept_terms').is(':checked')){
            $('.special-docs-submit-btn').removeClass('disabled').attr('disabled', false).removeAttr('disabled');
        } else {
            $('.special-docs-submit-btn').addClass('disabled').attr('disabled', true);
        }
    });
});

$('.special-docs-submit-btn').on('click', function(e) {
    var validator = $('.special-docs-form').validate();
    var data = {
            region_code: window.region_code,
            language_code: window.language_code,
            email: $('input[name=email]').val(),
            first_name: $('input[name=first_name]').val(),
            last_name: $('input[name=last_name]').val(),
            accept_terms: $('input[name=accept_terms]').is(":checked"),
            newsletter_signup: $('input[name=newsletter_signup]').is(":checked"),
            contact_title: $('input[name=contact_title]').val(),
            object_id: $('input[name=object_id]').val(),
            object_model: $('input[name=object_model]').val()
        };


    if($('.special-docs-form').valid()) {
        $('.special-docs-submit-btn').button('loading');
        $.ajax({
            type: 'POST',
            url: '/special_documents/process_request_form',
            data: data,
            headers: {
                'X-CSRFToken': getCookie('csrftoken'),
            },
            success: function(response) {
                if (response.status == 'success') {
                    $('.document_success').show();
                    $('.document_error').hide();
                    $('.form-errors').hide();
                }
                else {
                    $('.document_success').hide();
                    $('.document_error').show();
                }
                $('.special-docs-form').validate().destroy();
                $('.special-docs-submit-btn').button('reset');
                $('form, .special-docs-submit-btn').hide();
            },
            error: function(response) {
                $('.special-docs-submit-btn').button('reset');
                $('.document_success').hide();
                $('.document_error').show();
            }
        })
    }
    else {
        for (var i=0;i<validator.errorList.length;i++){
            if (validator.errorList[i]['method'] != 'required') {
                $(validator.errorList[i]['element']['parentElement']).attr('data-error', validator.errorList[i]['message']);
            }
            $(validator.errorList[i]['element']['parentElement']).addClass('invalid');
        }
        $('.document_success').hide();
        $('.form-errors').show();
    }
})

$('.special-docs-form input').on('focus', function(){
    $(this.parentElement).removeClass('invalid');
    $(this.parentElement).find('.errorlist').hide();
});

$('#specialDocsModal').on('shown.bs.modal', function (e) {
  $('form, .special-docs-submit-btn').show();
  $('.special-docs-form #id_accept_terms').prop('checked', false);
  $('.special-docs-submit-btn').addClass('disabled').attr('disabled', true);
  $('.document_success, .document_error, .form-errors').hide();
  $('.special-docs-form .input-wrap').removeClass('invalid');
})
