$(document).ready(function () {
    var contact_subj = $('#id_contact_subj');
    var contact_app = $('#id_contact_app');
    var contact_third_category = $('#id_contact_third_category');
    var selected_app = getQueryString().contact_app;
    var contact_area = $('#id_contact_area');
    var contact_technology = $('#id_contact_technology');
    var selected_technology = getQueryString().contact_technology;

    contact_app.chosen({
        inherit_select_classes: true
    });

    contact_third_category.chosen({
        inherit_select_classes: true
    });

    contact_technology.chosen({
        inherit_select_classes: true
    });

    contact_area.on('change', function(e) {
        var data = {
            contact_area: this.value,
            region_code: window.region_code,
            language_code: window.language_code
        };

        $.ajax({
            type: 'POST',
            url: '/contact/contact-form/contact_subject_list_for_area',
            data: data,
            headers: {
                'X-CSRFToken': getCookie('csrftoken'),
            },
            success: function(response) {
                contact_subj.empty();
                if (response.data.length > 0) {
                    response.data.forEach(function (e) {
                        $option = $("<option></option>").attr("value", e['id']).text(e['name']);
                        contact_subj.append($option);
                    });
                }
                contact_subj.trigger('chosen:updated');
                contact_subj.trigger("change");
            }
        })
    })

    contact_subj.on('change', function(e) {
        var data = {
            contact_subj: this.value,
            region_code: window.region_code,
            language_code: window.language_code
        };
        if (this.value) {
            $.ajax({
                type: 'POST',
                url: '/contact/contact-form/contact-third-category',
                data: data,
                headers: {
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                success: function(response) {
                    contact_app.empty();
                    contact_third_category.empty();
                    contact_technology.empty();
                    if (response.type == 'COMPANY' && response.data.length > 1) {
                        response.data.forEach(function (e) {
                            $option = $("<option></option>").attr("value", e[0]).text(e[1]);
                            contact_third_category.append($option);
                        });
                        $('#id_contact_third_category_chosen').show();
                        $('#id_contact_app_chosen').hide();
                        $('#id_contact_technology_chosen').hide();

                    }
                    else if (response.type == 'TECHNICAL' && response.data.length > 1) {
                        response.data.forEach(function (e) {
                            $option = $("<option></option>").attr("value", e[0]).text(e[1]);
                            if (parseInt(selected_technology) == e[0])
                                $option.attr("selected", "selected");
                            contact_technology.append($option);
                        });
                        $('#id_contact_third_category_chosen').hide();
                        $('#id_contact_app_chosen').hide();
                        $('#id_contact_technology_chosen').show();
                    }
                    else if(response.data.length > 1) {
                        response.data.forEach(function (e) {
                            $option = $("<option></option>").attr("value", e[0]).text(e[1]);
                            if (parseInt(selected_app) == e[0])
                                $option.attr("selected", "selected");
                            contact_app.append($option);
                        });
                        $('#id_contact_third_category_chosen').hide();
                        $('#id_contact_app_chosen').show();
                        $('#id_contact_technology_chosen').hide();
                    }
                    else {
                        hide_third_dropdown();
                    }
                    contact_app.trigger('chosen:updated');
                    contact_third_category.trigger('chosen:updated');
                    contact_technology.trigger('chosen:updated');
                }
            })
        }
        else {
            hide_third_dropdown();
        }
    });
    hide_third_dropdown();
    contact_subj.trigger('change');

    function hide_third_dropdown() {
        $('#id_contact_app_chosen').hide();
        $('#id_contact_third_category_chosen').hide();
        $('#id_contact_technology_chosen').hide();
    }
});

