$(document).ready(function() {
    $('.login-modal-btn').button('reset');
})

$('.login-modal-btn').on('click', function(e) {
    var validator = $('.modal-login-form').validate();
    var data = {
            username: $('input[name=username]').val(),
            password: $('input[name=password]').val(),
            area: $('input[name=area]').val(),
        };

    if ($('.modal-login-form').valid()) {
        $('.login-modal-btn').button('loading');
        $.ajax({
            type: 'POST',
            url: '/special_content/login_request',
            data: data,
            headers: {
                'X-CSRFToken': getCookie('csrftoken'),
            },
            success: function(response) {
                $('.modal-login-form').validate().destroy();
                if (response.status == 'success') {
                    window.location.reload();
                }
                else {
                    $('.document_error').show();
                    $('.login-modal-btn').button('reset');
                }
            },
            error: function(response) {
                $('.login-modal-btn').button('reset');
                $('.document_error').show();
            },
        })
    }
    else {
        for (var i=0;i<validator.errorList.length;i++){
            if (validator.errorList[i]['method'] != 'required') {
                $(validator.errorList[i]['element']['parentElement']).attr('data-error', validator.errorList[i]['message']);
            }
            $(validator.errorList[i]['element']['parentElement']).addClass('invalid');
        }
        $('.form-errors').show();
    }
})

$('.modal-login-form input').on('focus', function(){
    $(this.parentElement).removeClass('invalid');
    $(this.parentElement).find('.errorlist').hide();
});

