var translations = {
        'no_results_text': gettext('No results match'),
        'placeholder_text_single': gettext('Select an option'),
        'placeholder_text_multiple': gettext('Select some options')
};

window.current_language = $('html').attr('lang');

window.isMobile = /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent);
var touchDevice = (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement || (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches));

window.timeouts = [];

function clearTimeouts() {
    $.each(window.timeouts, function(index, value){
        clearTimeout(value);
    });
    window.timeouts = [];
}

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function getQueryString() {
    var queries = {};
    if(document.location.search)
        $.each(document.location.search.substr(1).split('&'),function(c,q){
            var i = q.split('=');
            if (i.length > 1) {
                queries[i[0].toString()] = i[1].toString();
            }
        });
    return queries;
}

function removeURLParameter(url, parameter) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {

        var prefix = encodeURIComponent(parameter)+'=';
        var pars = urlparts[1].split(/[&;]/g);

        for (var i = pars.length; i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
        return url;
    } else {
        return url;
    }
}

function sideNav(){
    var sections = $("section").not(".ignore-nav");
    function isEnoughSections() {
            var sections = $("section").not(".ignore-nav");
            return sections.length >= 2;
        }

    if (isEnoughSections()) {
        var sidenav = document.createElement("div");
        var contact_button = $('#contact-button');
        var city_page_button = $('#city-page-button');
        var newsletter_page_button = $('#newsletter-page-button');

        $(sidenav).addClass("sidenav");
        var top_button = document.createElement("div");
        $(top_button).addClass("sidenav-top-button");
        $(top_button).html('<div class="title">' + gettext('Go to top') + '</div>');
        $(sidenav).append(top_button);
        if (contact_button.length != 0 || city_page_button.length != 0 || newsletter_page_button.length != 0) {
            $(sidenav).addClass('sidenav-shifted');
        }
        $(sections).each(function(index){
            var label_text = '';
            var header = null;
            var h1 = $(this).find("h1");
            var h2 = $(this).find("h2");
            var h3 = $(this).find("h3");
            var item = document.createElement("div");
            $(item).addClass("sidenav-item");

            if ( h1.length > 0 ) {
                header = h1.eq(0);
            } else if (h2.length > 0) {
                header = h2.eq(0);
            } else if (h3.length > 0) {
                header = h3.eq(0);
            }

            if (header && header.text()) {
                label_text = header.text();
            } else {
                $(item).addClass('no-title');
                $(item).css('display', 'none');
                $(this).addClass('ignore-nav');
            }
            $(item).html('<div class="title">' + label_text + '</div>');
            $(sidenav).append(item);
            if (isEnoughSections()) {
               $("body").append(sidenav);
            }
        });

        var items = $(".sidenav-item");

        function getHeights() {
            var heights = $(sections).map(function(indx, element) {
                return $(element).offset().top;
            });
            return heights.get();
        }

        function setActive() {
            var heights = getHeights();
            var scroll = $(window).scrollTop();
            for (var i = 0; i < heights.length; i++) {
                if (((scroll >= heights[i] - 160 && scroll <= heights[i+1] ) || (!heights[i+1] && scroll >= heights[i] - 160)) && !$(items).eq(i).hasClass("active")) {
                    $(items).removeClass('active');
                    $(items).removeClass('hovered');
                    $(items).eq(i).addClass("active");
                    $(items).eq(i).find('.title').addClass("hovered");
                    $(items).eq(i).addClass("hovered").delay(2000).queue(function(next){
                        $(this).removeClass("hovered");
                        next();
                    });
                }
            }
        }

        function smoothScroll(int) {
            var scroll = getHeights()[int];
            $('body,html').stop(true,true).animate({
                scrollTop: scroll + 1
            }, 500);
        }

        setActive();
        if ( $(window).scrollTop() >= (getHeights()[0]) ) {
            $(sidenav).addClass("visible");
        }

        $(".sidenav-item").click(function(e) {
            e.preventDefault();
            smoothScroll($(this).index(".sidenav-item"));
        });

        $(".sidenav-top-button").click(function(e) {
            e.preventDefault();
            $('body,html').stop(true,true).animate({
                scrollTop: 1
            }, 500);
            $(this).off('mouseenter mouseleave');
        });

        $(window).scroll(function(){
            if ( $(window).scrollTop() >= getHeights()[0] - 160 ){
                $(sidenav).addClass("visible");
            } else {
                $(sidenav).removeClass("visible");
                $(items).removeClass("active");
            }


            setTimeout (function(){
                setActive();
            }, 500);
        });
    }
}

function contactButton() {
        var contact_button = $('#contact-button');
        var city_page_button = $('#city-page-button');
        var newsletter_page_button = $('#newsletter-page-button');
        var sections = $("section").not(".ignore-nav");
        var homepage_sections = $("div.homepage__section");

        function getHeights(target_el) {
            var heights = $(target_el).map(function(indx, element) {
                return $(element).offset().top;
            });
            return heights.get();
        }

        $(window).scroll(function(){
            if (($(window).scrollTop() >= getHeights(sections)[0] - 160) || ($(window).scrollTop() >= getHeights(homepage_sections)[0] - 160)){
                contact_button.addClass("visible");
                city_page_button.addClass("visible");
                newsletter_page_button.addClass("visible");
            } else {
                contact_button.removeClass("visible");
                city_page_button.removeClass("visible");
                newsletter_page_button.removeClass("visible");
            }
        });
}

function submenuHeight() {
    if ($(window).width() <= 1024) {
        $('.submenu').each(function() {
            $(this).attr('style', false);
        });
    }
    else if ($(window).height() <= 600) {
        $('.submenu').each(function() {
            $(this).attr('style', false);
            if ($(this).outerHeight() > $(window).height()) {
                $(this).css('height', $(window).height() - 175 + 'px');
            }
        });
    }
    else {
        $('.submenu').each(function() {
            var contentHeight = $(window).height() - $(this).parent().position().top - $(this).parent().height();
            var defaultHeight = $(this).outerHeight();
            var l2Items = $(this).find('.menu-l2-item');
            var l2ItemsLength = l2Items.length * 50 + 100; //l2 items count * average of item height + margin of l2 content
            if (l2ItemsLength > defaultHeight)
                defaultHeight = l2ItemsLength;

            l2Items.each(function() {
                var l3ItemsLength = $(this).find('.menu-l3-item').length * 50 + $(this).find('.menu-l3-heading').actual('outerHeight') + 100;
                if (l3ItemsLength > defaultHeight)
                    defaultHeight = l3ItemsLength;
            });
            if (defaultHeight > contentHeight)
                defaultHeight = (contentHeight - 20);
            $(this).css('height', defaultHeight + 'px');
        });
    }
}

function allowCookies() {
    var is_allowed = (localStorage.getItem('cookie_allowed')||'false') == 'true',
        message = $('#cookie-alert');

    if ( !is_allowed ) {
        message.slideDown();
    }

    $('#cookies-allowed').on('click', function () {
        localStorage.setItem('cookie_allowed', 'true');
        message.slideUp();
    });
}

function resize(){
    var hasMap = $(".map-container").length > 0;
    if (hasMap) {
        var img = $(".map-container").find("img");

        var aspect =  $(img).width()/$(img).height();
        if (aspect < 1) {
            $(img).addClass("high");
        }
    }
}

var doSubmit = false;

function reCaptchaExpired () {
    grecaptcha.reset();
}

function reCaptchaVerify(response) {
    if (response === grecaptcha.getResponse()) {
        doSubmit = true;
    } else {
        doSubmit = false;
    }
    if (doSubmit) {
        $('#captcha-wrap').removeClass("invalid");
    } else {
        $('#captcha-wrap').addClass("invalid");
    }
}

function reCaptchaCallback () {
    grecaptcha.render('g-recaptcha', {
        'sitekey': window.ckey,
        'callback': reCaptchaVerify,
        'expired-callback': reCaptchaExpired,
        // 'hl': window.language_code
    });
}

$(document).ready(function () {
    if ($('#init_bewerbung').is(':checked')) {
        $('#job_advert').removeAttr('required');
        $('#application_as').attr('required', 'required');
        $(".check-hidden").hide();
        $(".check-visible").show();
    } else {
        $('#job_advert').attr('required', 'required');
        $('#application_as').removeAttr('required');
        $(".check-hidden").show();
        $(".check-visible").hide();
    }

    // if the job details are not present, then show the job selection options
    if (!$('.vacancy').length) {
        $('#application_type').removeAttr('style');
        $('#hidden_job_advert').remove();
    } else {
        $('#job_advert').removeAttr('required');
    }

    $("#init_bewerbung").on('change', function(){
        if ($('#init_bewerbung').is(':checked')) {
            $('#job_advert').removeAttr('required');
            $('#application_as').attr('required', 'required');
            $(".check-hidden").hide();
            $(".check-visible").show();
        } else {
            $('#job_advert').attr('required', 'required');
            $('#application_as').removeAttr('required');
            $(".check-hidden").show();
            $(".check-visible").hide();
        }
    });

    $(".hide-btn").on('click', function(){
        $('#application_type').removeAttr('style');
        $('#hidden_job_advert').remove();
        $('#job_advert').attr('required', 'required');
        $(this).parent().hide();
    });


    //Panel groups handling
    $(".panel-group").click(function(){
        var isOpened = $(this).hasClass("opened");
        $(".panel-group").removeClass("opened");

        if (isOpened) {
            $(this).removeClass("opened")
        } else {
            $(this).addClass("opened");
        }

    });

    // Cookie alert initialize
    allowCookies();

    // Side navigation initialize
    sideNav();

    // Set Contact button
    contactButton();

    // Set submenus height
    submenuHeight();

    $(window).resize(function() {
        submenuHeight();
    });

    var scrollDelay = 1000;
    var scrollTimeout = null;
    $(window).bind('scroll',function(){
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(function(){
            submenuHeight();
        }, scrollDelay);
    });

    // Call resize only when image is loaded
    $('.map-container img').on('load', function () {
        resize();
    });

    $("select").not(".template select").chosen({
        disable_search_threshold: 10,
        width: "100%",
        no_results_text: translations['no_results_text'],
        placeholder_text_single: translations['placeholder_text_single'],
        placeholder_text_multiple: translations['placeholder_text_multiple'],
    });

    $(".input-wrap").each(function(){
        if ( $(this).find('.chosen-container-multi').length > 0 ) {
            $(this).append('<div class="chosen-add-close"></div>')
        }
    });


    $(".form-reset").click(function(e){
        e.preventDefault();
        var form = $(this).closest("form");
        $(form).trigger("reset");
        $(form).find("input[type=text], input[type=search], textarea").val("");
        $(".chosen-select").val('').trigger("chosen:updated");
    });


    $(".submit-btn").click(function(e){
        if ($('#captcha-wrap').length){
            if (doSubmit) {
                $('#captcha-wrap').removeClass("invalid");
            } else {
                $('#captcha-wrap').addClass("invalid");
                e.preventDefault();
            }
        }

        var form = $(this).closest("form");
        var inputs = $(form).find("input:invalid");
        var selects = $(form).find("select:invalid");
        $(selects).parent().addClass("invalid");
        inputs.each(function (index, input) {
            if (!input.validity.valueMissing && input.validity.patternMismatch) {
                $(input).parent().attr('data-error', $(input).parent().attr('data-error-pattern'));
            } else {
                if ($(input).parent().attr('data-error-required')) {
                $(input).parent().attr('data-error', $(input).parent().attr('data-error-required'));
                }
            }
        });
        $(inputs).parent().addClass("invalid");
        if (form.hasClass('job_application_form')) {
            var dates_valid = validDatesSelect();
            if(!dates_valid) {
                e.preventDefault();
            }
        }
    });

    // Fairs filter

    if ( $('body').is('.fairs-page') ) {
        var $checkbox = $('#fairs-checkbox'),
            $country = $('#fair-country'),
            $sector = $('#fair-sector'),
            $query = $('#fair-search'),
            $type = $('#fair-type');

        function getValues() {
            return {
                past: $checkbox.is(':checked'),
                country: $country.val(),
                sector: $sector.val(),
                query: $query.val(),
                type: $type.val()
            }
        }

        function formUpdated(event) {
            var data = getValues(),
                query = [];

            for ( var i = 0; i < Object.keys(data).length; i++ ) {
                var key = Object.keys(data)[i],
                    value = data[key];

                if (Array.isArray(value)) {
                    $.each(value, function(i, val){
                        query.push(
                            key + '=' + encodeURIComponent(val)
                        )
                    })
                }
                else {
                    query.push(
                        key + '=' + encodeURIComponent(value)
                    )
                }
            }

            document.location.href = '?' + query.join('&')
        }

        $checkbox.on('click', formUpdated);
        $country.on('change', formUpdated);
        $sector.on('change', formUpdated);
        $query.on('change', formUpdated).focus();
        $query.on('keydown', function(event){
            if (event.keyCode == 13) {
                formUpdated();
            }
        });
        $type.on('change', formUpdated);
        $('#fairs_search_icon').on('click', formUpdated);
    }


    // News filter

    if ($('#news-search')) {
        $('#news-search').on('keydown', function(e) {
            if ( e.keyCode == 13 ) {
                e.preventDefault();
                var currentUrlParams = getUrlParams(window.location.href);
                currentUrlParams.q = $('#news-search').val();
                delete currentUrlParams.page;

                window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
            }
        });
        $('#news_search_icon').on('click', function(e) {
             e.preventDefault();
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.q = $('#news-search').val();
            delete currentUrlParams.page;

            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        })
    }

    // Results filter

    function setSearchParams() {
        var currentUrlParams = getUrlParams(window.location.href);
        currentUrlParams.q = $('#results-search').val();
        delete currentUrlParams.page;

        window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
    }

    if ($('#results-search')) {
        $('#results-search').on('keydown', function(e) {
            if ( e.keyCode == 13 ) {
                e.preventDefault();
                setSearchParams();
            }
        });

        $('#results_search_icon').on('click', function(e) {
            e.preventDefault();
            setSearchParams();
        });
    }


    if ($('#news-year')) {
        $('#news-year').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.year = this.value;
            delete currentUrlParams.page;

            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    if ($('#news-cat')) {
        $('#news-cat').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.category = this.value;
            delete currentUrlParams.page;

            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    if ($('#id_item_type')) {
        $('#id_item_type').on('change', filterResults);
    }

    function filterResults(event) {
        var currentUrlParams = getUrlParams(window.location.href);
        currentUrlParams.item_type = this.value;
        delete currentUrlParams.page;

        window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
    }



    // Tabs

    $(".tab-btn").click(function (e) {
        e.preventDefault();
        var target = $(this).attr("href");
        var group = $(this).attr("data-parent");
        $("#" + group + " > .tabs-controls > .tab-btn").removeClass("active");
        $("#" + group + " > .tab-content > .tab-content-block" ).removeClass("visible");
        $(this).addClass("active");
        $(target).addClass("visible");
    });


    //News cards

    $(".news-card").each(function(){

        var isDetailed = $(this).hasClass("detailed");
        var txt = $(this).find(".news-card-text");
        var p = $(this).find(".news-card-text > p");

        if (p.length > 2 && !isDetailed) {

            $(txt).append('<button class="btn news-btn"><span class="btn-ico_chevron_down"> read more</span></button>');
            $(p[1]).append('<span class="expand-mark"> (...) </span>');

            for (var i = 2; i < p.length; i ++) {
                $(p[i]).addClass("js-spoil");
            }


            $(".js-spoil").wrapAll('<div class="spoiler">');
            $(".js-spoil").removeClass("js-spoil");
        }

    });

    //Job filters spoiler

    $(".form-spoiler-toggle").click(function(e){

        e.preventDefault();
        var parent = $(this).parent();
        var spoiler = $(parent).find(".form-spoiler");
        $(this).toggleClass("closed");

        $(spoiler).slideToggle(500, function(){
            $(spoiler).toggleClass("hidden");
            $(spoiler).attr("style", false);
        });

    });

    // Job Spoilers

    $(".job-folder-head:not(.always-open)").click(function(){
        var target = $(this).parent().find(".job-folder-content");
        $(this).toggleClass("opened");
        $(target).slideToggle();
    });

    $(".always-open").each(function(){
        var target = $(this).parent().find(".job-folder-content");
        $(this).toggleClass("opened");
        $(target).slideToggle();
    });

    $(".folder-toggle").click(function(){
        var parent = $(this).parent();
        var target = parent.find(".folder-content");
        $(this).toggleClass("opened");
        target.slideToggle();
    });

    // Custom file uploaders

    $("body").on("click", ".file-upload-button", function(e){
        if (!$(this).hasClass('btn-error')) {
            var input = $(this).attr("data-upload");
            $(input).click();
            e.preventDefault();
        }
    });

    function removeFile(e) {
        if ( $(this).hasClass("btn-error") ) {
            var parent = $(this).parent().parent();
            var input = parent.find(".js-input-form");
            var fileDisplay = parent.find(".js-filename");
            input.wrap('<form>').closest('form').get(0).reset();
            input.unwrap();
            e.preventDefault();
            e.stopPropagation();
            $(this).removeClass("btn-error").html( $(this).attr("data-browse") );
            fileDisplay.html( $(fileDisplay).attr("data-empty") );
        }
    }

    $("body").on("change", ".js-input-form", function(){
        var file = $(this).val();
        var fileName = file.split("\\");
        var fileDisplay = $(this).parent().find(".js-filename");
        var button = $(this).parent().find(".js-file-btn");
        if (fileName[fileName.length-1] != "") {
            fileDisplay.html(fileName[fileName.length-1]);
            button.addClass("btn-error").html( $(button).attr("data-remove") );
            button.on('click', removeFile);
        } else {
            fileDisplay.html( $(fileDisplay).attr("data-empty") );
            button.html( $(button).attr("data-browse") );
            button.removeClass("btn-error");
        }
    });

    // Map modals
    $(".map-modal-opener").click(openMapItem);

    $(".map-modal-header").click(function(){
        var target = $(this).attr("data-info");
        var group = $(this).attr("data-parent");
        var isOpened = $(target).is(":visible");

        if (!isOpened) {
            $(group).find(".map-modal-info").slideUp();
            $(target).slideDown();
        }
        else {
            $(target).slideUp();
        }

    });

    $(".map-modal-close").click(function(){
        var modal = $(this).parent();
        var contact_button = $('#contact-button');
        if (contact_button.length > 0) {
            contact_button.css('z-index', 999);
            var contactUrl = $(contact_button).attr('href');
            contactUrl = removeURLParameter(contactUrl, 'contact_app');
            $(contact_button).attr('href', contactUrl);
        }
        modal.find(".map-modal-info").attr("style", false);
        modal.fadeOut();
    });


    // Owl Carousels Setup

   $('#banner-carousel').owlCarousel({
        loop:true,
        nav:false,
        dots: true,
        autoplay:true,
        smartSpeed:1000,
        autoplayTimeout:7500,
        items: 1,
        autoHeight:false,
        lazyLoad: true,
        lazyLoadEager: 1
    });

    var anwendugenCarousel = $('#anwendungen-carousel');
    anwendugenCarousel.owlCarousel({

        loop: false,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        autoplayTimeout: 3000,
        mouseDrag: false,

        responsive: {
            0: {
                items: 1
            },
            670: {
                items: 2
            },
            900: {
                items: 3
            },

            1200: {
                items: 4
            }
        }
    });

    var anwendugenCarousel = $('[id^="benefits-carousel"]');
    anwendugenCarousel.owlCarousel({

        loop: false,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        autoplayTimeout: 3000,
        mouseDrag: false,

        responsive: {
            0: {
                items: 1
            },
            670: {
                items: 2
            },
            900: {
                items: 3
            },

            1200: {
                items: 4
            }
        }
    });

    var relatedCarousel = $('[id^="related-carousel"]');
    relatedCarousel.owlCarousel({

        loop: false,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        controls: false,
        autoplayTimeout: 3000,
        mouseDrag: false,
        items: 4,

        responsive: {
            0: {
                items:1
            },

            768: {
                items:3
            },

            1024 : {
                items:4
            }
        }
    });

    var carsCarousel = $('#cars-carousel');

    carsCarousel.owlCarousel({
        loop: false,
        margin: 40,
        nav: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        controls: false,
        autoplayTimeout: 3000,
        mouseDrag: false,
        items:7,

        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            900: {
                items: 3
            },

            1100: {
                items: 4
            },

            1400: {
                items: 5
            }
        }
    });

    $(".owl-next").html('<span class="btn-ico_chevron_right"></span>');
    $(".owl-prev").html('<span class="btn-ico_chevron_left"></span>');

    $(document).on('click', '.flip-card', function(){
        $(this).toggleClass("flipped");
    });

    // Filter on download and news page

    function getUrlParams(url) {
        var splited_url = url.split('?'),
            result = {};
        if (splited_url.length > 1) {
            var list_params = splited_url[1].split('&');
            list_params.forEach(function(param) {
                var pair = param.split('=');
                if (pair.length > 1) {
                    result[pair[0]] = pair[1];
                }
            });
        }
        return result;
    }

    function makeUrl(url, params) {
        var new_url = url.toString();
        if (Object.keys(params).length > 0) {
            new_url += '?';
            for (var key in params) {
                if (params[key] !== '') {
                    new_url += key + '=' + params[key] + '&';
                }
            }
            return new_url.substr(0, new_url.length - 1);
        }
        return new_url;
    }

    if ($('#downloads-categories-filter')) {
        $('#downloads-categories-filter').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.cat = this.value;
            delete currentUrlParams.page;
            delete currentUrlParams.cat_pk;
            delete currentUrlParams.topic;
            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    if ($('#downloads-types-filter')) {
        $('#downloads-types-filter').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.type = this.value;
            delete currentUrlParams.page;
            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    if ($('#downloads-languages-filter')) {
        $('#downloads-languages-filter').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.lang = this.value;
            delete currentUrlParams.page;
            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    if ($('#downloads-topics-filter')) {
        $('#downloads-topics-filter').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.topic = this.value;
            delete currentUrlParams.page;
            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    if ($('#news-year-filter')) {
        $('#news-year-filter').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.year = this.value;
            delete currentUrlParams.page;
            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    if ($('#news-category-filter')) {
        $('#news-category-filter').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.category = this.value;
            delete currentUrlParams.page;
            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    if ($('#search-filter')) {
        $('#search-filter').submit(function(e) {
            e.preventDefault();
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.q = $('#search-filter input').val();
            delete currentUrlParams.page;
            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
        $('#downloads_search_icon').on('click', function() {
            $('#search-filter').submit();
        })
    }

    if ($('#contracts-technologies-filter')) {
        $('#contracts-technologies-filter').on('change', function() {
            var currentUrlParams = getUrlParams(window.location.href);
            currentUrlParams.technology = this.value;
            delete currentUrlParams.page;
            window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
        });
    }

    // Pagination

    if ($('.pagination')) {
        $('.pagination a').on('click', function(e) {
            e.preventDefault();
            var page = e.target.getAttribute('data-page'),
                currentUrlParams = getUrlParams(window.location.href);
            if (page) {
                if (currentUrlParams.page == page) {
                    return;
                } else {
                    currentUrlParams.page = page;
                }
                window.location.href = makeUrl(window.location.href.split('?')[0], currentUrlParams);
            }
        });
    }


    // Attach image on top right bull list position
    $('[data-pin-to]').each(function(i, $img){
        $img = $($img);
        var $anch = $($($(this).attr('data-pin-to'))[0]),
            $image = $($img[0].outerHTML);

        if ( $anch.length == 0 ) {
            // If has no UL
            $img.show();
        } else {
            // Create new <li>
            $anch.prepend('<li class="image-right-top"></li>');
            var $container = $anch.find('li.image-right-top');

            // Append image to container
            var lazy_child_img = $image.find('img.lazy_img');
            var lazy_child_org = $img.find('img.lazy_img');
            if(lazy_child_img.length) {
                [lazy_child_img[0], lazy_child_org[0]].forEach(function(el) {
                    el.src = lazy_child_img[0].dataset.src;
                    el.classList.remove("lazy_img");
                })
            }
            $image.appendTo($container).show();
        }
    });

    // Attach image on top of first child element
    $('[data-attach]').each(function (i, $img) {
        $img = $($img);
        var $anch = $($($img.attr('data-attach')).find('h1')[0]),
            $image = $($img[0].outerHTML);

        if ( $anch.length == 0 ) {
            $img.show();
        } else {
            if ( $img.hasClass('middle') ) {
                // If image has middle position
                $image.appendTo($anch).show();
            } else {
                // If image left or right
                $image.insertAfter($anch).show();
            }
        }
    });

    imagePopUp();
});

// Show popup with author on image
function imagePopUp() {
  var moveLeft = 10;
  var moveDown = 10;

  $('a.image-trigger').hover(function(e) {
    $(this).find('div#author-pop-up').show();
  }, function() {
    $(this).find('div#author-pop-up').hide();
  });

  $('a.image-trigger').mousemove(function(e) {
    $(this).find("div#author-pop-up").css('top', e.offsetY + moveDown).css('left', e.offsetX + moveLeft);
  });
}

// Interactive map handling
function positionInteractiveMapItems() {
    resize();
    $('.map-item-pin').off('click');

    var map = $('.interactive-map');

    $('.legend-point-descr').each(function(index) {
        $(this).data('map-item-id', index);
        if ($(this).hasClass('legend-active')) {
            $(this).data('map-related-item-id', index);
        }
        $(this).on('click', openMapItem);
    });

   $('.map-item-pin').each(function() {
        var mapDotItem = $(this);
        var status = mapDotItem.data('map-status');
        var tooltip = $(this).find('.point-tooltip');
        positionInteractiveMapItem(mapDotItem, map);
        var position_left = $(this).position().left;

        mapDotItem.show().css({'opacity': 0}).fadeTo(500, 0.9, function() {
            if (status === 'active') mapDotItem.addClass('animated');
            mapDotItem.on('mouseenter', function() {
                if (status === 'active') mapDotItem.removeClass('animated');
            }).on('mouseleave', function() {
                if (status === 'active') mapDotItem.addClass('animated');
            });
        });
        tooltip.removeClass('right_side left_side');
        if (screen.width < 860) {
            if (position_left > screen.width/2 && (screen.width - position_left) < 280) {
                tooltip.addClass('right_side');
            }
            else if (position_left < screen.width/2 && (screen.width - 280) > position_left) {
                tooltip.addClass('left_side');
            }
        }
    });
}


// Open modal dialog
function openMapItem(e) {
    e.preventDefault();
    var target = $(this).attr("data-modal");
    var applicationId = $(target).find('#application-id').val();
    var contact_button = $('#contact-button');
    var isOpened = $(target).attr("style") == "display: block;";
    if (contact_button.length > 0 && applicationId) {
        contact_button.css('z-index', 9999);
        var contactUrl = $(contact_button).attr('href');
        $(contact_button).attr('href', contactUrl + '&contact_app=' + applicationId);
    }
    $(".map-modal").stop(true, true).fadeOut();
    if (!isOpened) {
        $(target).stop(true, true).fadeIn();
        $(target).find(".map-modal-info:first-of-type").stop(true, true).fadeIn();
    }

    return false;
}


// Positioning map point item
function positionInteractiveMapItem(mapDotItem, map) {
    var mapPercentPixelWidth = $(map).outerWidth() * 0.01;
    var mapPercentPixelHeight = $(map).outerHeight() * 0.01;

    mapPositions = $(map).offset();

    var mapDotPositions = {
        top: mapPositions.top,
        left: mapPositions.left
    };

    var rects = mapDotItem[0].getClientRects()[0];
    $(mapDotItem).css({
        'top': -1 * (rects.width / 2) + (mapPercentPixelHeight * mapDotItem.data('map-position-y')) + 'px',
        'left': -1 * (rects.height / 2) + (mapPercentPixelWidth * mapDotItem.data('map-position-x')) + 'px'
    }).addClass('trigger-ready');

    $(mapDotItem).on('click', openMapItem);
}


// Solution for equal heights for elements two section content
function equalHeights(elem) {
    var viewport = $(window).width();
    $(elem).attr("style", false);

    if (viewport > 1024) {
        var a = $(elem).eq(0).height();
        var b = $(elem).eq(1).height();

        if (a >= b) {
            $(elem).css("min-height", a + "px");
        } else {
            $(elem).css("min-height", b  + "px");
        }
    }

}


// Fair Spoilers

$(".fair-btn").click(function(){
    var target = $(this).parent().find(".fair-spoiler");
    var isVisible = $(target).attr("style") == "display: block;";
    $(target).slideToggle();

    if ($('html').attr('lang') == 'de') {
        if (isVisible) {
            $(this).html('<span class="btn-ico_chevron_down"> weiterlesen</span>');
        } else {
            $(this).html('<span class="btn-ico_chevron_up"> verkleinern</span>');
        }
    } else {
        if (isVisible) {
            $(this).html('<span class="btn-ico_chevron_down"> read more</span>');
        } else {
            $(this).html('<span class="btn-ico_chevron_up"> reduce</span>');
        }
    }

});


// $(document).ready(function () {
//     $(".row").each(function () {
//         var columns = $(this).find(".js-text-frame");
//         equalHeights(columns);
//     });
//
//     $(window).resize(function () {
//
//             $(".row").each(function () {
//                 var columns = $(this).find(".js-text-frame");
//                 equalHeights(columns);
//             });
//
//     });
// });



//History rows

$(document).ready(function (){

    if ( $(".history").length > 0) {

        function resetTimeline(elems, container) {
            elems.attr("style", false);
            elems.attr("style", false);
            container.attr("style", false);
        }

        function timeline(elems, container) {
            try {
                resetTimeline(elems, container);

                var viewport = $(window).width();

                if (viewport <= 900) {
                    return false;
                }

                var free_cells = 0;

                elems.each(function (index) {
                    var step = 100;
                    var this_h = $(this).height();
                    var this_cells = Math.ceil( this_h/step );

                    $(this).css('height', this_cells * step);

                    var diff = this_cells - free_cells;

                    if (index === 0) {
                        free_cells = this_cells;
                    }

                    if (diff >= 0 && index !== 0) {
                        $(this).css('margin-top', (free_cells - 1) * step * -1 );
                        free_cells = this_cells - (free_cells - 1);
                    }

                    if (diff < 0 && index !== 0) {
                        $(this).css('margin-top', (free_cells + diff) * step * -1 );
                        $(this).css('margin-bottom', diff * step * -1 );
                        free_cells = diff * -1;
                    }
                });

                free_cells = 0;

                var top = elems.eq(0).offset().top;
                var bottom = elems.eq(elems.length - 1).offset().top + elems.eq(elems.length - 1).height();
                container.css("height", bottom - top + 130);
            } catch (e) {
                console.warn(e);
            }
        }

        timeline($(".history-row"), $(".history"));

        $(window).on("resize", function(){
            var viewport = $(window).width();

            if (viewport <= 900) {
                resetTimeline( $(".history-row"), $(".history"))
            } else {
                timeline($(".history-row"), $(".history"))
            }

            squareImage();
        });

        squareImage();

    }
});


function squareImage() {
    $('img[data-square]').each(function (i, e) {
        e.style.height = e.getBoundingClientRect().width + 'px';
    });
}


function mobileDeviceHeader(mainMenu, preHead) {
    if (!preHead.hasClass('fixed-small-border')){
       preHead.addClass('fixed-small-border');
    }

    if (!preHead.hasClass('fixed-pre-head')){
        preHead.addClass('fixed-pre-head');
    }

    if (!mainMenu.hasClass('fixed-small-main-menu')) {
        mainMenu.addClass('fixed-small-main-menu');
    }
}


function smallScreenDeviceHeader(mainMenu, preHead) {
    if ($(window).scrollTop() >= 48) {
        if (!preHead.hasClass('fixed-pre-head')){
            preHead.addClass('fixed-pre-head');
        }
    }
    if ($(window).scrollTop() >= 96) {
        if (!preHead.hasClass('fixed-small-border')){
            preHead.addClass('fixed-small-border');
        }

        if (!mainMenu.hasClass('fixed-small-main-menu')) {
            mainMenu.addClass('fixed-small-main-menu');
        }
    }
}


//header scroll function
function headerScroll() {
    var mainMenu = $('#main-menu');
    if ($(this).width() > 1024) {
        if ($(this).scrollTop() >= 78) {
            if(!mainMenu.hasClass('fixed-main-menu')) {
                mainMenu.addClass('fixed-main-menu');
            }
        }
        else {
            mainMenu.removeClass('fixed-main-menu');
        }
    }
    else {
        var preHead = $('#pre-head');
        if ($(this).width() >= 755) {
            if ($(this).scrollTop() >= 96) {
                if (!preHead.hasClass('fixed-small-border')){
                    preHead.addClass('fixed-small-border');
                }

                if (!mainMenu.hasClass('fixed-small-main-menu')) {
                    mainMenu.addClass('fixed-small-main-menu');
                }
            }
            else {
                preHead.removeClass('fixed-small-border');
                mainMenu.removeClass('fixed-small-main-menu');

            }
            if ($(this).scrollTop() >= 48){
                if (!preHead.hasClass('fixed-pre-head')){
                    preHead.addClass('fixed-pre-head');
                }
            }
            else {
                preHead.removeClass('fixed-pre-head');
            }
        }
    }
}

function toDate(date) {
    if(date.length == 2 && $.inArray('', date) == -1)
        if(date[0].length == 4){
            return new Date(date.join('-'));
        }
        else{
            var transformedDate = date.reverse().join('-');
            if (transformedDate.length == 6)
                transformedDate = transformedDate.slice(0, 5) + '0' + transformedDate.slice(5);
            return new Date(transformedDate);
        }
    return null;
}

function showInvalidSelectFolder(select) {
    $(select).closest('.job-folder-content').show();
    $('.folder-content').show();
}

function validateDatesFromTo(to){
    var from_input = $('#' + $(to).attr('validate-to-id'));
    var to_selects = $(to).find('select');
    var to_year_select = $(to).find('select[id*="year"], select[id*="_to_y"]').parent();
    var to_date = [];
    to_selects.each(function(index) {
        to_date.push($(this).val());
    });

    var from_selects = $(from_input).find('select');
    var from_date = [];
    from_selects.each(function(index) {
        from_date.push($(this).val());
    });

    from_date = toDate(from_date);
    to_date = toDate(to_date);
    is_valid = to_date ? from_date <= to_date : true;
    if (from_date && to_date)
        if (!is_valid) {
            $(to_year_select).addClass('invalid');
            showInvalidSelectFolder(to);
            return is_valid;
        }
    $(to_year_select).removeClass('invalid');
    return is_valid;
}

function validDatesSelect() {
    var validate_to_selects = $('div[validate-to-id]');
    var is_valid = true;
    validate_to_selects.each(function (index) {
        if (!validateDatesFromTo(this))
            is_valid = false;
    });
    return is_valid;
}


//Set fixed class for header
$(document).ready(function(){
    //Check device width
    var mainMenu = $('#main-menu,#site-select,#header-search');
    var preHead = $('#pre-head');
    if ($(window).width() < 755) {
        mobileDeviceHeader(mainMenu, preHead);
    }
    else {
        if ($(window).width() > 1024) {
            if ($(window).scrollTop() >= 110) {
                if(!mainMenu.hasClass('fixed-main-menu')) {
                    mainMenu.addClass('fixed-main-menu');
                }
            }
        }
        else {
            smallScreenDeviceHeader(mainMenu, preHead);
        }
    }

    $(window).scroll(headerScroll);

    $(window).resize(function() {
        preHead.removeClass('fixed-small-border');
        preHead.removeClass('fixed-pre-head');
        mainMenu.removeClass('fixed-small-main-menu');
        mainMenu.removeClass('fixed-main-menu');
        if ($(this).width() > 1024) {
            if ($('#main-menu').attr('style') != 'none')
                $('#main-menu').attr('style', false);
            if ($(this).scrollTop() >= 110) {
                if(!mainMenu.hasClass('fixed-main-menu')) {
                    mainMenu.addClass('fixed-main-menu');
                }
            }
        }
        else {
            if ($(this).width() < 755){
                mobileDeviceHeader(mainMenu, preHead);
            }
            else {
                smallScreenDeviceHeader(mainMenu, preHead);
            }
        }
    });

});


//Temp menu solution
$(document).ready(function(){
    if ( $(".main-menu").length > 0) {
        $(".submenu").each(function(){
            var l2 = $(this).find(".menu-l2");
            if ( $(l2).length == 0) {
                var cont = $(this).find(".submenu-content");
                $(cont).addClass("no-level-two");
            }
        })
    }
    toggleDesktopTouchMenu();
    // On close
    if ( $('body').hasClass('confirm-exit') ) {
        bindUnload();
    }

    $(window).on('resize orientationchange', function() {
        toggleDesktopTouchMenu();
    });
});


function toggleDesktopTouchMenu() {
    if (touchDevice && window.innerWidth > 1024) {
        $(".main-menu").addClass('dt_touch_menu');
    }
    else {
        $(".main-menu").removeClass('dt_touch_menu');
    }
}


/**
 * Bind unload
 */
function bindUnload() {
    window.onbeforeunload = function() {return true;};
}


/**
 * Unbind unload
 */
function unbindUnload() {
    window.onbeforeunload = null;
}


/**
 * Map points positioning
 */
function Positioning() {
    this.applied = false;
    this.document_ready = false;
    this.map_loaded = false;
}

/**
 * Start positioning watchers
 */
Positioning.prototype.start = function() {
    var self = this;

    // When document ready
    $(function () {
        console.log('dom-loaded');
        self.onready(true);
    });

    // When map loaded
    $('.interactive-map').on('load', function () {
        console.log('map-loaded');
        self.onready(true);
    });

    // When window resized
    $(window).on('resize', function() {
        console.log('window-size-changed');
        self.onready(true);
    });

    // Run primitive watcher
    this.primitiveWatch();
};

/**
 * Run primitive watcher
 */
Positioning.prototype.primitiveWatch = function () {
    var self = this;
    setTimeout(function () {
        if ( !self.isApplied() && self.document_ready && self.map_loaded ) {
            console.log('primitive-applied');
            self.onready();
            return self.primitiveWatch();
        }
    }, 500);
};

/**
 * Applied
 * @returns {boolean}
 */
Positioning.prototype.isApplied = function () {
    return $('.map-item-pin.trigger-ready').length > 0;
};

/**
 * Apply points position
 */
Positioning.prototype.onready = function (force) {
    if ( force || !this.applied ) {
        positionInteractiveMapItems();
    }

    this.applied = this.isApplied();
};

/**
 * Run watcher
 */
(new Positioning()).start();