function initAgentMap() {
    function createTooltip(obj) {
        var string ='';
        obj.units.forEach(function (unit) {
            var website = '';
            var marker_phone = '';
            var marker_fax = '';
            var marker_website = '';
            var marker_header = '<h5>' + unit.organization + '</h5>';
            var marker_street = obj["street"] + '<br/>';
            var marker_city = obj["post"] + ' ' + obj["city"] + '</br>';
            if (unit["phone"]) marker_phone = '<span class="phone-tab">Tel:</span>' + unit["phone"] + '</br>';
            if (unit["fax"]) marker_fax = '<span class="phone-tab">Fax:</span>' + unit["fax"] + '</br>';
            if (unit["website"]) {
                if (unit["website"].indexOf('http://') != -1)
                    website = unit["website"].split('http://')[1] || '';
                else
                    website = unit["website"];
                marker_website = '<span class="phone-tab">Website: ' + website + '</span></br>';
            }
            var marker_subhead = "";
            if (unit["name"]) {
                marker_subhead = '<h6>' + unit["name"] + '</h6>';
            }
            string += '<div>' + marker_header + marker_subhead + '<div class="tooltip-addr">' + marker_street + marker_city+ '</div>' + marker_phone + marker_fax + marker_website + '</div>'
        });

        var html = '<div class="marker dialog-marker" data-markerId='+obj.id+'>' + string + '</div>';
        return html;

    }

    //Get coordinates from marker and create marker
    function geocodeAddress(obj, markers_array, from_search) {
        var info = createTooltip(obj);
        map.setCenter(obj.coords);
        var mapImage = image;
        var type = obj.type;
        if (type == 'agent') { mapImage = image_agent};
        if (type == 'independent_distributor') { mapImage = image_indep_distributor};

        var marker = new google.maps.Marker({
            id: obj.id,
            map: map,
            icon: mapImage,
            position: obj.coords
        });

        if (markers_array) {
            markers_array[obj.id] = marker;
            if (from_search){
                infowindow.setContent(info);
                infowindow.open(map, marker);
            } else {
                init_bounds.extend(marker.getPosition());
            }
        }
        else {
            search_marker = marker;
        }

        marker.addListener('click', function () {
            infowindow.setContent(info);
            infowindow.open(map, marker);
            createDialog(obj);
        });
    }

    function filterMarkers(filter) {

        var bounds = new google.maps.LatLngBounds();
        infowindow.close();
        search_marker.map = null;
        visible_markers = 0;

        (window.markers).forEach(function (elem) {
            var id = elem.id;
            var continentMatch = filter.continent == elem.continent || filter.continent == 'all';
            var countryMatch = filter.country == elem.country_code || (filter.country == 'all');

            if ( countryMatch && continentMatch) {
                bounds.extend(markers[id].getPosition());
                markers[id].setVisible(true);
                visible_markers += 1;

            }
            else {
                markers[id].setVisible(false);
            }
        });

        map.fitBounds(bounds);
    }

    function getOptions(keyA, valA, keyB) {
        var obj = {};
        (window.markers).forEach(function(elem){
            if (keyB == 'units') {
                if (elem[keyA] == valA) {
                    elem[keyB].forEach(function(unit){
                        var str = unit.type;
                        obj[str] = true;
                    })
                }
            } else {
                if (elem[keyA] == valA) {
                    var str = elem[keyB];
                    obj[str] = true;
                }
            }
        });
        return Object.keys(obj);
    }

    function getFolderNames(markersArray, key, value) {
        var obj = {};
        (markersArray).forEach(function(elem) {
            if (key != null && value != null && elem[key] != value) {
                return;
            }
            elem['units'].forEach(function (unit) {
                var str = unit.type;
                obj[str] = true;
            });
        });
        return Object.keys(obj);
    }

    function hideOptions(id) {
        $(id + ' option').each(function(){
            if ( $(this).val() != 'all') {
                $(this).hide();
            }
        });
    }

    function updateCountry(id, options) {
        hideOptions(id);
        options.forEach(function(val){
            $(id + ' option[value="'+ val +'"]').show();
        });
        $(id).val("all").trigger("chosen:updated");
    }

    //Show all markers
    function showAll(markersArray){
        for (var key in markersArray) {
            markers[key].setVisible(true);
        }
    }

    function hideAll(markersArray){
        for (var key in markersArray) {
            markers[key].setVisible(false);
        }
    }

    function createDialog(card) {
        $('[data-markerId='+card.id+']').on('click', function(e) {
            if (card.website != '') {
                dialog.find('a').attr('href', card.website);
                dialog.stop(true, true).fadeIn();
            }
        });
    }

    var infowindow = new google.maps.InfoWindow();

    var dialog = $('#agent-dialog');

    dialog.find('.agent-modal-close').on('click', function(e) {
        dialog.stop(true, true).fadeOut();
    });

    var image = {
        url: window.markers ? window.url_map_marker : 'images/map-marker.png',
        size: new google.maps.Size(33, 42),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 40)
    };

    var image_agent = {
        url: window.markers ? window.url_map_agent : 'images/map-marker_agent.png',
        size: new google.maps.Size(33, 42),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 40)
    };

    var image_indep_distributor = {
        url: window.markers ? window.url_map_indep_distributor : 'images/map-marker)indep_distributor.png',
        size: new google.maps.Size(33, 42),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 40)
    };

    var map = new google.maps.Map(document.getElementById('map'), {
        scrollwheel: false,
        zoom: 6,
        maxZoom: 9
    });

    var markers = {};
    var search_marker = new google.maps.Marker();
    var init_bounds = new google.maps.LatLngBounds();
    var visible_markers = 0;

    (window.markers).forEach(function (elem) {
        geocodeAddress(elem, markers);
    });

    $(document).ready(function () {
        var filtersGroup = $(".agents-filter");
        var locFilter = {};

        //Reset all filters
        function reset(){
            filtersGroup.attr('data-countries', 'all').attr('data-continents', 'all');
            $(".agents-filter").removeClass("country-selected").removeClass("continent-selected");
            hideOptions('#country');
            $("#continent").val("all").trigger("chosen:updated");
            $("#country").val("all").trigger("chosen:updated");
            updateLocFilter('all', 'all');
            showAll(markers);
            map.panTo(new google.maps.LatLng({lat:20, lng:0}));
            map.setZoom(2);
        }

        function updateLocFilter (a, b){
            history.replaceState({}, null, window.location.pathname);
            locFilter.continent = a;
            locFilter.country = b;
        }

        function setContinent(continent) {
            if (continent != 'all') {
                var countryList = getOptions('continent', continent, 'country_code');
                $(".agents-filter").removeClass("country-selected").addClass("continent-selected");
                filtersGroup.attr('data-continents', continent);
                updateLocFilter(continent, 'all');
                showAll(markers);
                filterMarkers(locFilter);
                updateCountry('#country', countryList);
                createCards('continent', continent);
                if (countryList.length == 1) {
                    $(".agents-filter").removeClass("continent-selected");

                    setCountry(countryList[0]);
                }
            } else {
                $('[data-continent]').show();
                init();
            }
        }

        function getWindowMarkers(key, value) {
            if (key != null && value != null) {
                return (window.markers).filter(function(e) {
                    return e[key] == value;
                });
            }
            else {
                return window.markers;
            }
        }

        function setCountry(country){
            var companyList = getOptions('country_code', country, 'units');
            if (country != "all") {
                $(".agents-filter").addClass("country-selected");
            } else {
                $(".agents-filter").removeClass("country-selected");
            }

            $("#company").val("all").trigger("chosen:updated");
            filtersGroup.attr('data-countries', country);
            updateLocFilter(filtersGroup.attr('data-continents'), country, 'all');
            showAll(markers);
            filterMarkers(locFilter);
            createCards('country_code', country);
        }

        function createFolders(cl){
            var container = $(".locations-companies");
            container.html('');
            cl.forEach(function(e){
                if ( !e ) {
                    return;
                }
                container.append('<div class="locations-folder" data-company="'+ e +'"><div class="trigger closed">'+ folderTypes[e] +'</div><div class="content row flex-row"></div></div>')
            });
        }

        function createCards(key, value) {
            var window_markers = getWindowMarkers(key, value);
            var cl = getFolderNames(window_markers, key, value);
            createFolders(cl);

            window_markers.forEach(function(e){
                createCard(e);
            });
        }

        function createCard(card) {
            card.units.forEach(function(unit){
                var folder = $('[data-company="'+ unit.type +'"]').find(".content");
                var name='';
                var sub1 = '';
                var sub2 = '';
                var header = '';
                var address = '';
                var contacts = '';
                var type = '';
                var website = ''
                var prefix = '<p class="unit-name">'+unit.typeName+'</p>';

                if (unit.sub1) { sub1 = '<p class="gray">' + unit.sub1 + '</p>' }
                if (unit.sub2) { sub2 = '<p class="gray">' + unit.sub2 + '</p>' }
                if (unit.name) { name = '<h6>' + unit.name  + '</h6>' }
                if (unit.name||unit.organization) { header = '<h3>' + (unit.organization || unit.name) + '</h3>' }
                if (unit.cardType) { type = unit.cardType }
                if (card.street || card.city) { address = '<p class="gray">' + (card.street + '<br/>' ||'') + ' ' + (card.post + ' ' || '') + card.city + '</p>' }
                if (unit.phone) {contacts += '<span class="phone-tab">Tel:</span>'+ unit.phone +'<br/>'}
                if (unit.fax) {contacts += '<span class="phone-tab">Fax:</span>'+ unit.fax +'<br/>'}
                if (unit.website) {
                    if (unit.website.indexOf('http://') != -1)
                        website = unit.website.split('http://')[1] || '';
                    else
                        website = unit.website;
                    contacts += '<span class="phone-tab">Website: '+ website +'</span> <br/>'
                }
                if (contacts != '') { contacts = '<p class="gray">' + contacts + '</p>'}
                folder.append('<div class="col col-s3"><div data-post="'+ card.post + '" data-markerId="' + card.id +'" class="agent-card ' + type + '">' + prefix + header + name + sub1 + sub2 + address + contacts + '</div></div>');
                createDialog(card);
            });

        };

        var continents = {
            'EU': 'europe',
            'NA': 'north_america',
            'SA': 'south_america',
            'ME': 'middle_east',
            'AF': 'africa',
            'OC': 'oceania',
            'AS': 'asia'
        };

        var folderTypes = {
            'agent': gettext('Agents'),
            'independent_distributor': gettext('Independent Distributors')
        }


        //Changing continent value
        $('#continent').on("change", function(){
            var val = $(this).val();
            setContinent(val);
        });

        //Changing country value
        $('#country').on("change", function(){
            var val = $(this).val();
            setCountry(val);
        });

        $(document).on("click", ".trigger" ,function(){
            $(this).toggleClass("closed");
        });

        $('#reset').click(function(e){
            e.preventDefault();
            init();
        });

        function init() {
            reset();
            createCards(null, null);
        }

        init();

        $(".cleaner").click(function(){
            clearSearch();
        });
    });
}