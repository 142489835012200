//Cookieconsent configuration
var p;
var path = window.location.pathname.split('/');
var langLink = '/' + path[1] + '/' + path[2];
var lang_code = path[2];
var lang  = lang_code == 'zh' ? 'zh_hans' : lang_code;
var fullDataProtectionLink = langLink+'/other/datenschutzerklarung/';
var description_trans_string = gettext('Cookies and comparable technologies are used on our website to process personal data and end device information. This serves to ensure the technical operation of the site and certain functions and to enable the integration of external content and services. In certain cases, data may also be passed on to third parties. Further information on the cookies and technologies used can be found in this consent banner and in our <a aria-label="privacy policy" class="cc-policy-link" href="%(data_protection_link)s" target="_blank">data protection declaration</a>. This consent is voluntary and can be revoked at any time via the cookie settings, which you will find in our <a aria-label="privacy policy" class="cc-policy-link" href="%(data_protection_link)s" target="_blank">data protection declaration</a>.');
var formatted_description_string = interpolate(description_trans_string, {"data_protection_link": fullDataProtectionLink}, true);
var statistics_cookies = ['_ga', '_gat', '_gid', '_gcl_au'];
var confirm_personalization = false;
var cookieInitParams = {
  container: document.getElementById("content"),
  palette: {
      popup: {
          background: '#fff'
      },
      button: {
          background: 'transparent',
          border: '#000000',
          text: '#000000'
      },
  },
  content: {
      message: formatted_description_string,
      messageLink: gettext('data protection declaration'),
      header: gettext('Consent to cookies and other technologies'),
      close: gettext('Select all'),
      savePreference: gettext('Confirm selection'),
      onlyNecessary: gettext('Only necessary cookies & technologies'),
      href: '/other/datenschutzerklarung/',
      imprintHref: '/other/impressum/',
      termsOfUseHref: '/other/nutzungsbedingungen/',
      link: gettext('cookies'),
      imprintLink: gettext('Imprint'),
      termsOfUseLink: gettext('Terms of use'),
      privacyPolicyLink: gettext('Data Privacy Policy'),
      langLink: langLink,
      settings: gettext('Show details'),
      settingsExpanded: gettext('Hide details'),
      analyticsTitle: gettext('Statistics'),
      analyticsDescription: gettext('We want to improve the content of our website in order to provide an optimal user experience. We collect anonymous data for analysis and statistics. These cookies enable us, for example, to determine visitor numbers and visitor events.'),
      necessaryTitle: gettext('Necessary'),
      necessaryDescription: gettext('These cookies are required for the operation of the website in order to guarantee you unrestricted functionality.'),
      personalizationTitle: gettext('Other'),
      personalizationDescription: gettext('These cookies are used to show you personalized content according to your interests. This allows us to present you with offers that are particularly relevant to you.'),
      ariaLabelAllowSelected: gettext('allow selected cookies'),
      ariaLabelOnlyNecessary: gettext('only allow necessary cookies and technologies'),
      languageSettings: gettext('Language settings'),
      provider: gettext('Provider'),
      dataTypes: gettext('Data type(s)'),
      purposes: gettext('Purpose(s)'),
      languageSettingsPurposes: gettext('Saves the region and language selected by the user'),
      dataRetentionDuration: gettext('Duration of data retention'),
      durationMonth: gettext('Month'),
      CMPSettingsPurposes: gettext('Saves consent to cookies & other technologies'),
      durationYear: gettext('Year'),
      onPremiseSolution: gettext('on-premise solution'),
      technologiesUsed: gettext('Technologies used'),
      matomoDataTypes: gettext('Time of visit; Screen resolution; Files clicked or downloaded; Links clicked to external domains; User ID; Page speed; Page URL; Number of user visits; User agent; Browser information; Time zone; Time of user’s first visit; Page title; Usage data; Device information; Geographic location; Anonymised IP address; Referrer URL; Date and time of visit'),
      matomoPurposes: gettext('Analysis of website usage; Optimisation of the web offer'),
      additionalRecipients: gettext('Data recipient(s) in addition to Minimax'),
      asWebHost: gettext('As web host'),
      cookiesAndTrackingPixels: gettext('Cookies and tracking pixels'),
      leadlabDataTypes: gettext('Visiting behaviour, IP addresses if applicable'),
      leadlabPurposes: gettext('Analysis of which companies have visited our website'),
      externalContentsTitle: gettext('External contents'),
      recaptchaDataTypes: gettext('IP address and possibly other data required by Google for the reCAPTCHA service'),
      recaptchaPurposes: gettext('Query serves to distinguish whether entries on the website are made by a human being or improperly by automated, machine processing'),
      dataRecipient: gettext('Data recipient'),
      transferOfData: gettext('Transfer of data outside the EU / EEA'),
      transferOfDataDescription: gettext('Yes, by means of standard contractual clauses pursuant to Art. 46 DSGVO'),
      youtubeDataTypes: gettext('Interactions with the video; information about the website on which the video is embedded'),
      youtubePurposes: gettext('Provision of a video player'),
      googleMapsDataTypes: gettext('Your IP address; Date and time of the request; Content of the request (specific page); Data about your location; Website from which the request comes; Your browser type; Your operating system and its interface; The language setting and version of the browser software'),
      googleMapsPurposes: gettext('To provide a convenient map function on the website'),
      googleDataProcessing: gettext('Purpose(s) of data processing by Google'),
      googleDataProcessingDescription: gettext('Google uses the data for the purposes of advertising, market research and/or demand-oriented design of its own offer'),
      googleAnalyticsDataTypes: gettext('App updates, browser information, click path, date and time of visit, device information, downloads, Flash version, location information, JavaScript support, pages visited, purchase activity, referrer URL, usage data, widget interactions, IP address (We have activated IP anonymization on the website [so-called IP masking], so that your IP address is shortened by Google within the EU and the EEA before transmission to such an extent that it no longer allows conclusions to be drawn about individual persons. Only this anonymized IP address will be transmitted outside the EEA)'),
      googleAnalyticsPurposes: gettext('Analysis of website usage; optimization of the web offer'),
      googleAnalyticsDataProcessingDescription: gettext('Google uses the data for the purpose of advertising, market research and/or demand-oriented design of its own offer. In doing so, Google may link the data with other data of the user (such as search history, personal accounts, usage data of other devices and all other data that Google has on this user)'),
      dataStorageDuration: gettext('Duration of data storage'),
      durationMonths: gettext('months'),
      fbPixelPurposes: gettext('Personalized advertising, marketing, retargeting, analytics'),
      fbPixelDataTypes: gettext('IP address (truncated), cookie ID, pixel ID, website usage data, browsing session information, page location, URL of the website visited by the user, other browsing behavior (i.e. websites visited by the user after this website), referrer URL (i.e. the URL from which the user arrived at this website), user agent (e.g. browser, email or newsreader), operating system'),
      note: gettext('Note'),
      noteInfo: gettext('If you are registered with a Facebook service, Facebook can assign the visit to your account'),
 },
 elements: {
    header: '<h2 class="cc-header">{{header}}</h2>',
    moreinfo: '<a aria-label="imprint" class="cc-imprint-link" data-target="#imprint" href="{{langLink}}{{imprintHref}}" target="_blank">{{imprintLink}}</a>'+
    '<div class="privacy_info" id="imprint"></div>'+
    '<a aria-label="imprint" class="cc-imprint-link" data-target="#data_privacy" href="{{langLink}}{{href}}" target="_blank">{{privacyPolicyLink}}</a>'+
    '<div class="privacy_info" id="data_privacy"></div>'+
    '<a aria-label="imprint" class="cc-imprint-link" data-target="#terms_of_use" href="{{langLink}}{{termsOfUseHref}}" target="_blank">{{termsOfUseLink}}</a>'+
    '<div class="privacy_info" id="terms_of_use"></div>'+
    '<i class="expand_icon collapsed"></i><a id="change-settings" href="#">{{settings}}</a>',
    descriptions: '<div class="cc_cookie_descr"></div>',
    checkboxes: '<ul class="cc-checkboxes" style="padding-left:0px">' +
    '<li><input type="checkbox" checked="checked" id="cc-checkbox-necessary" disabled> <label id="cc-label-necessary" for="cc-checkbox-necessary" style="cursor: not-allowed;"><strong class="cc-label-block">{{necessaryTitle}}</strong></label></li>' +
    '<li><input type="checkbox" id="cc-checkbox-analytics"><label id="cc-label-analytics" for="cc-checkbox-analytics"><strong id="analytics-title" class="cc-label-block analytics-title-unchecked">{{analyticsTitle}}</strong></label></li>' +
    '<li><input type="checkbox" id="cc-checkbox-personalization"><label id="cc-label-personalization" for="cc-checkbox-personalization"><strong id="personalization-title" class="cc-label-block personalization-title-unchecked">{{personalizationTitle}}</strong></label></li>' +
    '</ul>',
    dismiss: '<a id="allow-cookies" aria-label="dismiss cookie message" class="cc-btn cc-dismiss cc-custom-button" style="border-color: rgb(0, 0, 0); display: block; margin-right: 15px;">{{close}}</a>',
    allow: '<a id="allow-selected-cookies" aria-label="{{ariaLabelAllowSelected}}" class="cc-btn cc-deny cc-custom-button cc-custom-deny" style="border-color: rgb(0, 0, 0); display: block;">{{savePreference}}</a>',
    deny: '<a id="deny-cookies" aria-label="{{ariaLabelOnlyNecessary}}" class="cc-btn cc-deny cc-custom-button cc-custom-deny" style="border-color: rgb(0, 0, 0); display: block;">{{onlyNecessary}}</a>',
    messagelink: '<div id="cookieconsent:desc" class="cc-message" style="margin-top: 5px;">{{message}}</div>',
  },
  compliance: {
    'opt-out': '<div class="cc-compliance cc-highlight cc-custom-opt-out"><span>{{deny}}</span><span>{{allow}}</span><span>{{dismiss}}</span></div>',
  },
  layout: 'cc-custom-layout',
  layouts: {
    'cc-custom-layout': '<div class="cc-custom-layout">{{header}}{{messagelink}}{{checkboxes}}{{moreinfo}}{{descriptions}}{{compliance}}</div>'
  },
  onPopupOpen: function(){
        var checkbox = document.querySelector('#cc-checkbox-analytics');
        var checkbox_personalization = $('#cc-checkbox-personalization');
        var allowCookiesButton = $('#allow-cookies');
        var denyCookiesButton = $('#deny-cookies');
        var allowSelectedCookiesButton = $('#allow-selected-cookies');
        var changeSettingsLink = $('#change-settings');
        var checkboxesList = document.querySelector('.cc-checkboxes');
        var hideDetailsText = cookieInitParams.content.settingsExpanded;
        var showDetailsText = cookieInitParams.content.settings;
        var closeCookiesText = cookieInitParams.content.close;
        var savePreferenceText = cookieInitParams.content.savePreference;
        var analyticsTitle = document.querySelector('#analytics-title');
        var personalization_title = $("#personalization-title");
        if (!p) {
            p = this;
        }
        var data = {'language_code': lang, 'slugs': ['datenschutzerklarung', 'impressum', 'nutzungsbedingungen']};
        $(".cc-imprint-link").one("click", function() {
            $.ajax({
                type: 'POST',
                url: '/privacy/cookie_info_links',
                data: data,
                headers: {
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                success: function(response) {
                    $('#imprint').html(response['impressum']);
                    $('#data_privacy').html(response['datenschutzerklarung']);
                    $('#terms_of_use').html(response['nutzungsbedingungen']);
                }
            })
        })
        $('.cc-floating').removeClass('cc-floating').addClass('cc-banner');
    $('body').append('<div class="cookie_overlay"></div>');
    checkbox.addEventListener('change', changeHandler);
    allowSelectedCookiesButton.on('click', personalizationCheck);
    denyCookiesButton.on('click', function() {
      confirm_personalization = false;
    })
    function changeHandler(e) {
        analyticsTitle.classList.toggle('analytics-title-unchecked');
        if (e.target.checked){
            allowSelectedCookiesButton.addClass('cc-allow').removeClass('cc-deny');
        } else {
            allowSelectedCookiesButton.addClass('cc-deny').removeClass('cc-allow');
        }
    }
    function personalizationCheck(e) {
        if ($('#cc-checkbox-personalization').is(":checked")){
            confirm_personalization = true;
        } else {
            confirm_personalization = false;
        }
    }
    changeSettingsLink.on('click', function(e){
        if ($('.expand_icon').hasClass('collapsed')) {
            $.ajax({
                type: 'POST',
                url: '/privacy/cookie_details',
                data: {'language_code': lang},
                headers: {
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                success: function(response) {
                    $('.expand_icon').removeClass('collapsed');
                    $('.cc_cookie_descr').html(response);
                    $('.cc_cookie_descr').show();
                    changeSettingsLink.text(hideDetailsText);
                }
            })
        }
        else {
            $('.expand_icon').addClass('collapsed');
            $('.cc_cookie_descr').hide();
            changeSettingsLink.text(showDetailsText);
        }
        e.stopImmediatePropagation();
    });
    checkbox_personalization.on('change', function() {
        personalization_title.toggleClass('personalization-title-unchecked');
    });
    $('.cc-imprint-link').on('click', function(e) {
        e.preventDefault();
        var target = $($(this).data('target'));
        $('.privacy_info').not(target).hide();
        target.toggle();
        e.stopImmediatePropagation();
    })
    $('.cc-policy-link').on('click', function(e) {
        e.preventDefault();
        $('.privacy_info').not('#data_privacy').hide();
        $('#data_privacy').show();
    })
    $(document).on("click", ".privacy_info .wiredminds-opt-out", function() {
        alert_text = gettext('You are now excluded from tracking.');
        alert(alert_text);
    });
  },
  onInitialise: function(status) {
      var type = this.options.type;
      var didConsent = this.hasConsented();
      if (type == 'opt-out') {
        if (!didConsent) {
            optOutAnalytics();
        } else {
            var privacy_settings = getCookie('privacy-setting');
            var global_analytics = getCookie('cc_analytics') || 'ask';
            if ((privacy_settings === 'opt-out') || (privacy_settings === 'global' && global_analytics === 'never')) {
                optOutAnalytics();
            } else {
                optInAnalytics();
            }
        }
      }
  },

  onStatusChange: function(status) {
      var current_privacy_setting = getCookie('privacy-setting');
      if (status == 'deny') {
          cookie_set = false;
          optOutAnalytics();
          statistics_cookies.forEach(function(cname) {
            eraseCookie(cname);
          });
      }
      if (status == 'allow' && current_privacy_setting != 'allow') {
          optInAnalytics();
      }
      if (status == 'dismiss') {
        $("#analytics-title").removeClass('analytics-title-unchecked');
        $("#personalization-title").removeClass('personalization-title-unchecked');
        $("#cc-checkbox-analytics, #cc-checkbox-personalization").prop('checked', 'checked');
        if (current_privacy_setting != 'allow') {
            optInAnalytics();
        }
      }
      if ((status != 'deny' || confirm_personalization) && $('#cc-checkbox-personalization').is(":checked")) {
         optInPersonalization();
      }
      else if(getCookie('personalization-opt-in') != null) {
        setCookie('personalization-opt-in', false, -1);
        eraseCookie('_fbp');
      }
  },
  onPopupClose: function() {
    $('.cookie_overlay').remove();
    $('.privacy_info').hide();
  },
  type: 'opt-out',
  location: true,
  position: 'top',
  revokeBtn: '<div></div>',
}


window.cookieconsent.initialise(cookieInitParams, function(popup){
    p = popup;
});

cc_button = document.getElementsByClassName('cc-button')[0];
if (cc_button)
  cc_button.addEventListener('click', function(){
            p.revokeChoice();
  });

$('.cc-settings-button').on('click', function(){
    p.open();
    var current_privacy_setting = getCookie('privacy-setting');
    var current_personalization_choice = getCookie('personalization-opt-in');
    if (current_privacy_setting == 'allow') {
      $('#allow-selected-cookies').addClass('cc-allow').removeClass('cc-deny');
      $("#cc-checkbox-analytics").prop('checked', 'checked');
      $("#analytics-title").removeClass('analytics-title-unchecked');
    }
    else {
      $("#cc-checkbox-analytics").prop('checked', false);
      $('#allow-selected-cookies').addClass('cc-deny').removeClass('cc-allow');
      $("#analytics-title").addClass('analytics-title-unchecked');
    }
    if (current_personalization_choice == 'true') {
      $("#cc-checkbox-personalization").prop('checked', 'checked');
      $("#personalization-title").removeClass('personalization-title-unchecked');
    }
    else {
      $("#cc-checkbox-personalization").prop('checked', false);
      $("#personalization-title").addClass('personalization-title-unchecked');
    }
});

function initialiseGoogleAnalytics() {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', window.google_tracking_code, window.google_analytics_domain);
    ga('set', 'anonymizeIp', true);
    ga('send', 'pageview');
    if (window.local_google_tracking_code) {
        ga('create', window.local_google_tracking_code, window.local_google_analytics_domain, window.local_google_analytics_name);
        ga(window.local_google_analytics_name + '.set', 'anonymizeIp', true);
        ga(window.local_google_analytics_name + '.send', 'pageview');
    }
}

function initialiseGoogleTagManager() {
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', window.google_tag_manager_code);
}

function optOutGoogleAnalytics(tracking_code) {
    var disableStr = 'ga-disable-' + tracking_code;
    setCookie(disableStr, true);
    window[disableStr] = true;
}

function optInGoogleAnalytics(tracking_code) {
    var disableStr = 'ga-disable-' + tracking_code;
    setCookie(disableStr, false);
    window[disableStr] = false;
}

function optOutPiwikAnalytics() {
    var _paq = _paq || [];
    _paq.push(['disableCookies']);
    _paq.push(['trackPageView']);
}

function optOutAnalytics() {
    // disable cookies
    if (window.google_tracking_code) {
        optOutGoogleAnalytics(window.google_tracking_code);
    }
    if (window.local_google_tracking_code) {
        optOutGoogleAnalytics(window.local_google_tracking_code);
    }
    optOutPiwikAnalytics();
    setCookie('privacy-setting', 'opt-out');
}

function optInAnalytics() {
    if (window.google_tracking_code) {
        optInGoogleAnalytics(window.google_tracking_code);

    }
    if (window.local_google_tracking_code) {
        optInGoogleAnalytics(window.local_google_tracking_code);
    }
    var ga_loaded = window.ga && ga.loaded;
    if (!ga_loaded) {
        initialiseGoogleTagManager();
        initialiseGoogleAnalytics();
    }
    setCookie('privacy-setting', 'allow');
}

function initialiseFacebookPixel() {
   !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', window.fbpixel_id);
    fbq('track', 'PageView');
}

function optInPersonalization() {
    setCookie('personalization-opt-in', true, 365);
    initialiseFacebookPixel();
}